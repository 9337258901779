<template>
  <div class="banner-slider">
    <div class="swiper-slider">
      <div 
        class="swiper-wrapper"
        ref="wrapper"
      >
        <div
          v-for="(item, index) in items"
          :key="index"
          class="swiper-slide slider-item"
        >
          <a :href="item.href" target="_blank" :title="item.title">
            <img
              :src="item.mobile"
              class="slider-item__image image_mobile"
              alt=""
            />
            <img
              :src="item.desktop"
              class="slider-item__image image_desktop"
              alt=""
            />
          </a>
        </div>
      </div>
      
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Swiper, { Pagination } from "swiper";

Swiper.use([Pagination]);

export default {
  name: 'BannerSlider',
  props: {
    items: Array,
  },
  data() {
    return {
      swiper: null,
      stopSliding: false,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
  },
  methods: {
    createSwiper() {
      if (this.items.length < 2) return;

      setTimeout(() => { 
        this.swiper = new Swiper('.swiper-slider', {
          touchMoveStopPropagation: true,
          noSwipingSelector: '.swiper-button-prev, .swiper-button-next',
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          loop: true,
        });

        setInterval(() => {
          if (this.swiper) {
            this.swiper.slideNext(750);
          }
        }, 7000);
      }, 100);
    },
  },
  mounted() {
    this.createSwiper();
  }
};
</script>


<style lang="scss" scoped>
@import "~@rr-component-library/common/src/scss/vars.scss";
@import "~@rr-component-library/common/src/scss/mixins.scss";
@import "@/scss/colors.scss";

.banner-slider {
  width: 100%;
  height: auto;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.swiper-slider {
  position: relative;
}

.slider-item {
  &__image {
    display: block;
  }
}

.swiper-pagination {
  position: absolute;
  bottom: 16px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 16px;
  z-index: 2;
}

.swiper-container-initialized .swiper-pagination {
  display: flex;
}

.swiper-wrapper {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.swiper-slide {
  flex: 0 0 auto;
  width: 100%;
  min-height: 1px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  @media (min-width: breakpoint(md)) {
    &[data-swiper-slide-index="0"] {
      justify-content: center;
    }
  }

  &.swiper-slide-prev,
  &.swiper-slide-next {
    opacity: 1;

    @media (min-width: breakpoint(md)) {
      opacity: 0;
    }  
  }
  
  &.swiper-slide-active {
    opacity: 1;

    @media (min-width: breakpoint(md)) {
      opacity: 1;
    }  
  }
}

::v-deep .swiper-pagination-bullet {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $color-white-85;
  margin: 0 4px;
  cursor: pointer;

  &.swiper-pagination-bullet-active {
    background-color: $color-primary;
  }
}

.image {
  &_mobile {
    width: 100%;
    
    @media (min-width: breakpoint(md)) {
      display: none;
    }
  }

  &_desktop {
    display: none;

    @media (min-width: breakpoint(md)) {
      display: block;
      width: 360px;
    }
  }
}
</style>
