







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconExclamation",
})
export default class IconExclamation extends Vue {
}
