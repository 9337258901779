<template>  
  <div class="package-offers-item">
    <div class="package-offers-item__container">
      <div class="package-offers-item__image">
        <img :src="image" />
      </div>
      <div class="package-offers-item__content">
        <div class="content__heading">
          <div class="heading__text">
            <div class="text__title" v-html="title"></div>
            <div class="text__subtitle" v-html="subtitle"></div>
          </div>
          <div v-if="price" class="heading__price">
            <div class="price__current" v-html="price"></div>
            <div v-if="oldPrice" class="price__old" v-html="oldPrice"></div>
          </div>
        </div>
        <div v-if="action && !isAllPhotos" class="content__action">
          <Button
            variant="ghost"
            size="sm"
            :text="action.text"
            :iconLeft="action.icon"
            :view="getButtonState()"
            :disabledQuiet="state === offerStates.inCart"
            wide
            @click="onAction(action.func)"
          ></Button>
        </div>
        <div v-if="progress" class="content__progress">
          <div class="progress__line">
            <div
              v-for="(item, index) in progress.max"
              :key="index"
              class="line__item"
              :class="{ 'item_success': index + 1 <= progress.value }"
            ></div>
          </div>
          <div class="progress__circle" :class="{ 'circle_success': progress.value >= progress.max }">
            <Icon name="check-circle-s" size="l" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="warning" class="package-offers-item__warning">
      <div class="warning__text">
        <span v-html="$t('packageOffers/isValidFor')"></span><br>
        <span v-html="warning"></span>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { OfferTypes } from "@/offer-types";
import { OfferStates } from "@/offer-states";

export default {
  name: "PackageOffersItem",
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(OfferTypes).indexOf(value) !== -1;
      },
    },
    state: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(OfferStates).indexOf(value) !== -1;
      },
    },
    title: String,
    subtitle: String,
    warning: String,
    price: String,
    oldPrice: String,
    image: String,
    dependency: String,
    progress: Object,
    action: Object,
    isAllPhotos: Boolean,
  },
  data() {
    return {
      offerStates: OfferStates,
    };
  },
  computed: {
  },
  methods: {
    onAction(func) {
      if (func) func();
      this.$emit('action');
    },
    getButtonState() {
      if (this.$props.state === OfferStates.initial) return '';
      if (this.$props.state === OfferStates.inCart) return 'success';
      return '';
    },
  },  
};
</script>


<style lang="scss" scoped>
  @import "./package-offers-item";
</style>
