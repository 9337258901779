







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "HereArrow",
})
export default class HereArrow extends Vue {
}
