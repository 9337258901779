<template>
  <div class="possible-match">
    <div
      class="possible-match__photo"
      :class="vertical ? 'possible-match__photo_vertical' : ''"
      :style="{'background-image': 'url(' + (resources ? resources.watermark : '') + ')'}"
      @click.stop="$emit('show')"
    >
      <div v-if="showFrameAction" class="possible-match__frame" @click.stop="$emit('show')">
        <Button 
          @click.stop="$emit('frame')"
          variant="secondary"
          size="md"
          iconLeft="photo-in-frame"
          :text="$t('photoPreview/frame')"
          class="frame__button"
        ></Button>
      </div>
    </div>
    <div class="possible-match__action">
      <Button 
        @click.stop="$emit('itsme')"
        variant="ghost"
        :view="itIsMe ? 'success' : ''"
        iconLeft="check-bold"
        size="sm"
        :text="$t('findMore/thisIsMe')"
        :disabledQuiet="itIsMe"
        class=""
        wide
        mobile
      ></Button>
    </div>
  </div>
</template>


<script lang="js">
export default {
  name: 'PossibleMatch',
  props: {
    photoId: String,
    resources: Object,
    vertical: Boolean,
    itIsMe: Boolean,
    showFrameAction: Boolean,
  },
};
</script>


<style lang="scss" scoped>
  @import "./possible-match";
</style>
