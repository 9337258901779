<template>
  <div class="discount-container">
    <div
      v-for="(item, i) in items"
      :key="i"
      class="payment-discount"
      :class="items.length > 1 ? animationClasses[i] : []"
    >
      <img :src="item.img" class="payment-discount__image" />
      <span class="payment-discount__text" v-html="item.text"></span>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentDiscount',
  props: {
    items: Array,
  },
  data() {
    return {
      animationClasses: ['pop-out', 'pop-in'],
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
  },
  methods: {
    toggleClasses() {
      this.animationClasses = [...this.animationClasses.reverse()];
      setTimeout(() => { this.toggleClasses(); }, 6000);
    },
  },
  mounted() {
    if (this.items.length > 1) {
      this.toggleClasses();
    }
  },
};
</script>

<style lang="scss" scoped>
  @import './payment-discount.scss';
</style>
