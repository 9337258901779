







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconDiscount",
  props: {
    fill: {
      type: String,
      default: "var(--color-black)"
    },
  },
})
export default class IconDiscount extends Vue {
}
