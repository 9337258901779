







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconRight",
  props: {
    fill: {
      type: String,
      default: "white"
    },
    opacity: {
      type: String,
      default: "1"
    }
  },
})
export default class IconRight extends Vue {
}
