<template>
  <div class="nav" :class="!$root.isMobile ? 'nav--large' : ''">
    <div class="nav__item" :class="myPhotosClasses" @click.stop="!myPhotosDisabled ? $emit('show-my-photos') : ''">
      {{ $t('event/titleMy') }}
      <div v-if="myPhotosActive && !myPhotosDisabled" class="item__line"></div>
    </div>
    <div class="nav__item" :class="allPhotosClasses" @click.stop="$emit('show-all-photos')">
      {{ $t('event/titleAll') }}
      <div v-if="!myPhotosActive" class="item__line"></div>
    </div>
  </div>
</template>


<script lang="js">
export default {
  name: 'NavTabs',
  props: {
    myPhotosDisabled: Boolean,
    myPhotosActive: Boolean,
  },
  computed: {
    myPhotosClasses() {
      const classes = [];
      if (this.$props.myPhotosDisabled) classes.push('nav__item--disabled');
      else if (this.$props.myPhotosActive) classes.push('nav__item--active');
      return classes;
    },
    allPhotosClasses() {
      const classes = [];
      if (!this.$props.myPhotosActive) classes.push('nav__item--active');
      return classes;
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./nav-tabs";
</style>