<template>
  <div class="photos-with-frame" @click.stop="$emit('closed')">
    <div class="photos-with-frame__overlay" @click.stop="$emit('closed')"></div>

    <div class="photos-with-frame__modal">
      <div class="photos-with-frame__container" @click.stop>
        <div class="photos-with-frame__close" @click.stop="$emit('closed')">
          <Button
            variant="clear"
            shape="square"
            size="lg"
            iconLeft="close"
            mobile
            @click="$emit('closed')"
          />
        </div>

        <div class="photos-with-frame__title">
          <div class="title__text" v-html="$t('photosWithFrame/title')"></div>
        </div>

        <div class="photos-with-frame__content">
          <div 
            v-for="(item, index) in items"
            :key="index"
            class="content__item"
          >
            <div class="item__container">
              <div class="item__image"><img :src="getItemImage(item)" alt="" /></div>
              <div class="item__text">
                <span v-html="item.title"></span>
                <span v-html="getItemDescription(item)"></span>
              </div>
            </div>
            <div class="item__price">
              <span v-html="formatAmount(item.amount, true)"></span>
              <span
                v-if="item.singleFrameAmount && item.amount < (item.singleFrameAmount * photosCount)"
                v-html="formatAmount(item.singleFrameAmount * photosCount, true)"
              ></span>
            </div>
          </div>
        </div>

        <div class="photos-with-frame__actions">
          <Button
            size="lg"
            mobile
            wide
            :variant="alreadyInCart ? 'ghost' : 'primary'"
            :view="alreadyInCart ? 'success' : ''"
            :text="alreadyInCart ? $t('resultsItem/inCart') : $t('cart/addFrame')"
            :iconLeft="alreadyInCart ? 'check-bold' : 'photo-in-frame'"
            :disabled="adding"
            :disabledQuiet="alreadyInCart"
            :loading="adding"
            @click="$emit('frame')"
          ></Button>

          <div class="actions__divider">
            <div class="divider__line"></div>
            <div class="divider__text">{{ $t('searchResults/or') }}</div>
            <div class="divider__line"></div>
          </div>

          <Button
            variant="secondary"
            iconRight="right"
            size="lg"
            :text="$t('searchResults/goToCart')"
            mobile
            wide
            :disabled="adding"
            :loading="adding"
            @click="$emit('gotocart')"
          ></Button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { ProductTypes } from "@/product-types";
import Common from "@/plugins/common";

export default {
  name: 'PhotosWithFrame',
  props: {
    options: {
      type: Object,
      required: true,
    },
    adding: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      productTypes: ProductTypes,
    };
  },
  computed: {
    items() {
      return this.$props.options.items || [];
    },
    photosCount() {
      return this.$props.options.photosCount || 0;
    },
    alreadyInCart() {
      return this.$props.options.alreadyInCart === true;
    },
  },
  methods: {
    getItemImage(item) {
      if (!item) return '';
      if (item.productType === this.productTypes.photosWithFrame) return '/img/all-photos-in-frame.svg';
      if (item.productType === this.productTypes.frame) return '/img/photo-frame.png';
      return '';
    },
    getItemDescription(item) {
      if (!item) return '';
      if (item.productType === this.productTypes.photosWithFrame) return this.$t('photosWithFrame/description').toString();
      if (item.productType === this.productTypes.frame) return '';
      return '';
    },
    formatAmount(amount, onlyInteger = true) {
      return Common.formatAmount(amount, onlyInteger);
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./photos-with-frame";
</style>