













































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Debounce from "lodash/debounce";

import BaseComponent from "@/components/base-component.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import IconLeft from "@/components/icons/icon-left.vue";
import IconLeft2 from "@/components/icons/icon-left2.vue";
import IconRight from "@/components/icons/icon-right.vue";
import IconRight2 from "@/components/icons/icon-right2.vue";
import IconCloseCircleS from "@/components/icons/icon-close-circle-s.vue";
import IconSearch from "@/components/icons/icon-search.vue";
import IconSearch2 from "@/components/icons/icon-search2.vue";
import HereArrow from "@/components/illustrations/here-arrow.vue";
import { SearchState } from "@/store/modules/search";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "SearchRunner",
  props: {
    eventId: String,
    key_: String,
    containerId_: String,
  },
  components: {
    BaseIllustration,
    BaseIcon,
    IconLeft,
    IconLeft2,
    IconRight,
    IconRight2,
    IconCloseCircleS,
    IconSearch,
    IconSearch2,
    HereArrow,
  },
})
export default class SearchRunner extends BaseComponent {

  private readonly searchState: SearchState = getModule(SearchState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);
  
  private debouncedDoSearch: any = null;

  runnerName: string = "";

  inputElementId: string = "";

  containerElement: any = null;
  containerHeight: number = 0;
  formElement: any = null;
  formHeight: number = 0;

  showList: boolean = false;
  showNotFound: boolean = false;
  showError: boolean = false;
  hasScroll: boolean = false;

  pageOffset: { x: number, y: number } = { x: -1, y: -1 };

  get theme(): any {
    return this.settingsState.theme;
  }

  get items(): any[] {
    return this.searchState.runners.slice(0, 50);
  }

  get key(): string {
    return this.$props.key_;
  }

  showFiltered(item: any): string {
    if (this.runnerName == "") return "";

    const escaped: string = this.escapeRegExpChars(this.runnerName);
    const pattern: RegExp = new RegExp(escaped, "i");
    
    let title: string = this.getFullName(item) + " (№" + item.number + ")";
    const result = title.replace(pattern, 
      "<span class='font-weight-bold'>" + 
      "$&"+
      "</span>");

    return result;
  }

  escapeRegExpChars(regexp: string): string {
    if (regexp == "") return "";

    const chars: string[] = ["\\", "/", "[", "^", "$", ".", "|", "?", "*", "+", "(", ")"];
    
    let result: string = regexp;    
    for (let ch of chars) {
      result = result.replace(new RegExp("\\" + ch, "i"), "\\" + ch);
    }
    
    return result;
  }

  getFullName(item: any): string {
    return item.surname + " " + item.firstName;
  }

  getIconStyle(): any {
    if (!this.smOnly && !this.mdOnly && this.hasScroll) {
      return { 'right': '0' };
    } else if (this.smOnly || this.mdOnly) {
      return { 'right': '17px' };
    } else {
      return '';
    }
  }

  @Watch("key")
  async onKeyChanged(): Promise<void> {
    await this.scrollTop();
    if (this.runnerName.length > 0) {
      setTimeout(async () => {
          await this.doSearch();
        },
        100
      );
    }
    this.$emit('changed', this.showList);
    setTimeout(() => { this.setFocusToInput(); }, 100);
  }

  @Watch("formHeight")
  async onFormHeightChanged(): Promise<void> {
    this.hasScroll = this.formHeight > this.containerHeight;
  }

  async onTextChanged(value: any): Promise<void> {
    if (value != '') {
      this.debouncedDoSearch();
    } else {
      this.showList = false;
      this.$emit('changed', this.showList);
    }
  }

  async onClearText(): Promise<void> {
    await this.resetValues();
  }

  async onClick(): Promise<void> {
    const e = document.getElementById(this.inputElementId);
    if (e) e.focus();

    if (this.smOnly || this.mdOnly) return;
    if (!this.showList) {
      await this.doSearch();
    }
  }

  async onBlur(): Promise<void> {
    if (this.smOnly || this.mdOnly) return;
    
    setTimeout(async () => {
        await this.scrollBack();
        await this.searchState.clearFound();
        this.showList = false;
        this.$emit('changed', this.showList);
      },
      250,
    );
  }

  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
  }

  async doSearch(): Promise<void> {
    if (this.runnerName != '') {
      const payload: any = { eventId: this.$props.eventId, search: this.runnerName };
      await this.searchState.searchQuick(payload);

      this.showError = this.searchState.searchError;

      if (this.items.length > 0) {
        this.showNotFound = false;
        this.showList = true;
      } else {
        this.showNotFound = true;
        this.showList = true;
      }
    } else {
      this.showList = false;
      this.showNotFound = false;
    }

    setTimeout(async () => {
        this.$emit('changed', this.showList);
        await this.updateHeight();
      },
      100,
    );
  }

  async onSelected(item: any): Promise<void> {
    this.$emit('selected', item);
    this.showList = false;
    this.$emit('changed', this.showList);
    this.runnerName = this.getFullName(item) + " (№" + item.number + ")";
    await this.scrollBack();
    await this.searchState.clearFound();
  }

  async onCancel(): Promise<void> {
    this.$emit('cancelled');
    await this.resetValues();
    await this.scrollBack();
  }

  async resetValues(): Promise<void> {
    await this.searchState.clearFound();
    this.runnerName = "";
    this.showList = false;
    this.formHeight = 0;
    setTimeout(() => {
        this.$emit('changed', this.showList);
      },
      100,
    );
  }

  async updateHeight(): Promise<void> {
    if (!this.showList) return;
    
    const formId = 'runnerSearchForm' + (this.$props.key_ ? this.$props.key_ : '');
    this.formElement = document.getElementById(formId);
    if (this.formElement === null) return;

    if (!this.smOnly && !this.mdOnly) {
      this.containerHeight = this.containerElement.offsetHeight;
    } else {
      this.containerHeight = window.innerHeight;
    }
    this.formHeight = this.formElement.offsetHeight;

    await this.onFormHeightChanged();

    setTimeout(async () => {
        await this.updateHeight();
      }, 
      50
    );
  }

  async scrollTop(): Promise<void> {
    if (this.pageOffset.x == -1 && this.pageOffset.y == -1) {
      this.pageOffset = { x: window.pageXOffset, y: window.pageYOffset };
    }

    if (this.smOnly || this.mdOnly) {
      setTimeout(() => { window.scrollTo(0, 0); }, 50);
    }
  }

  async scrollBack(): Promise<void> {
    if (this.smOnly || this.mdOnly) {
      setTimeout(() => {
          window.scrollTo(this.pageOffset.x, this.pageOffset.y);
        },
        250
      );
    }
  }

  setFocusToInput(): void {
    const e = document.getElementById(this.inputElementId);
    if (e === null) {
      setTimeout(() => { this.setFocusToInput(); }, 50);
      return;
    }
    setTimeout(() => {
      e.focus();
      e.click();
    }, 100);
  }

  created(): void {
    this.debouncedDoSearch = Debounce(this.doSearch, 500);        
  }

  async mounted(): Promise<void> {
    const containerId = this.$props.containerId_;
    if (containerId) {
      this.containerElement = document.getElementById(containerId);
    }

    const formId = 'runnerSearchForm' + (this.$props.key_ ? this.$props.key_ : '');
    this.formElement = document.getElementById(formId);

    this.inputElementId = "search_runner_input_" + Math.random().toString();

    await this.scrollTop();

    setTimeout(async () => {
        await this.updateHeight();
      }, 
      50
    );
  }

}
