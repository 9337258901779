<template>
  <div 
    :class="!$root.isMobile ? 'd-flex justify-space-between' : ''"
  >
    <div v-if="!isAllPhotos" class="list__title" :class="$root.isMobile ? 'd-flex justify-space-between' : 'list__title--large d-flex'">
      <div
        @click.stop="$emit('backtosearch')"
        class="list__search"
        :class="(!$root.isMobile ? 'list__search--large' : '') + (faceImage ? ' list__search--selfie' : '')"
        :style="!$root.isMobile ? 'margin-right: 40px;' : ''"
      >
        <div
          v-if="faceImage" 
          :style="{'background-image': 'url(' + faceImage + ')'}"
          class="search__selfie" 
          :class="!$root.isMobile ? 'search__selfie--large' : ''"
        >
        </div>
        <span v-if="!faceImage">
          {{ $t('searchTitle/number') + runnerNum }}
        </span>
        <base-icon 
          width="16" 
          height="16" 
          viewBox="0 0 16 16" 
          class="search__icon"
        >
          <icon-reset fill="var(--color-danger)" />
        </base-icon>
      </div>
      <div v-if="!$root.isMobile" class="list__count list__count--large">
        {{ $t('searchTitle/found', [photosTotal]) }}
      </div>

      <Button 
        v-if="$root.isMobile && photosWithMeProduct !== null && !isPhotosWithMeInCart"
        @click.stop="!isAddingPhotosWithMe ? $emit('addtocart') : null"
        id="list__title-button"
        variant="primary"
        size="lg"
        class="list__title-button"
        :class="!$root.isMobile ? 'list__title-button--large' : ''"
      >
        <slot name="before">
          <span v-if="!isAddingPhotosWithMe" class="rr-button__icon" style="margin-right: 8px;">
            <base-icon v-if="theme === ''" width="18" height="18" viewBox="0 0 20 20">
              <icon-shopping-cart2 fill="currentColor" opacity="1" />
            </base-icon>
            <base-icon v-if="theme !== ''" width="18" height="18" viewBox="0 0 24 24">
              <icon-shopping-cart fill="currentColor" opacity="1" />
            </base-icon>
          </span>
          <Loader v-if="isAddingPhotosWithMe" size="18" fill="rgba(255, 255, 255, 0.87)" />
        </slot>
        <slot v-if="!isAddingPhotosWithMe">
          <span class="rr-button__text">
            {{ $t('searchTitle/buyAll') }} &middot; {{photosWithMeProduct ? formatAmount(photosWithMeProduct.amount, true) : ''}}
          </span>
        </slot>
      </Button>

      <span v-if="$root.isMobile && photosWithMeProduct !== null && isPhotosWithMeInCart" style="color: var(--color-success);">
        <Button 
          id="list__title-button"
          variant="ghost"
          view="success"
          size="lg"
          class="list__title-button list__title-button--in-cart"
          :class="!$root.isMobile ? 'list__title-button--large' : ''"
          disabledQuiet
        >
          <slot>
            <span class="rr-button__text">
              {{ $t('searchTitle/allPhotosInCart', [photosTotal]) }}
            </span>
          </slot>
        </Button>
      </span>
    </div>
    
    <div v-if="giftLabel && $root.isMobile && !isAllPhotos" class="list__gift">
      <GiftLabel :tipText="giftLabel" />
    </div>

    <div v-if="!$root.isMobile" class="d-flex justify-space-between" :style="isAllPhotos ? 'width: 100%;' : ''">
      <div v-if="isAllPhotos" class="list__count">
        {{ $t('searchTitle/found', [photosTotal]) }}
      </div>
      <div v-if="hasPackageOffers" @click.stop="$emit('offers')" class="list__offers list__offers--large">
        <base-icon width="16" height="16" viewBox="0 0 24 24" class="list__offers-icon">
          <icon-discount2 v-if="theme === ''" fill="var(--color-primary)" />
          <icon-discount v-if="theme !== ''" fill="var(--color-primary)" />
        </base-icon>
        {{ $t('searchTitle/packages') }}
      </div>

      <div v-if="giftLabel && !$root.isMobile && !isAllPhotos" class="list__gift">
        <GiftLabel :tipText="giftLabel" />
      </div>

      <Button 
        v-if="photosWithMeProduct !== null && !isPhotosWithMeInCart && !isAllPhotos"
        @click.stop="$emit('addtocart')"
        id="list__title-button"
        variant="primary"
        size="md"
        class="list__title-button"
        :class="!$root.isMobile ? 'list__title-button--large' : ''"
      >
        <slot v-if="isAddingPhotosWithMe" name="before">
          <Loader size="18" fill="rgba(255, 255, 255, 0.87)" />
        </slot>
        <slot v-if="!isAddingPhotosWithMe">
          <span class="rr-button__text">
            {{ $t('searchTitle/buyAll') }} &middot; {{photosWithMeProduct ? formatAmount(photosWithMeProduct.amount, true) : ''}}
          </span>
        </slot>
      </Button>

      <span v-if="!isAllPhotos" style="color: var(--color-success);" :style="{'width': $root.isMobile ? '100%' : 'auto'}">
        <Button 
          v-if="photosWithMeProduct !== null && isPhotosWithMeInCart"
          id="list__title-button"
          variant="ghost"
          view="success"
          size="md"
          class="list__title-button list__title-button--in-cart"
          :class="!$root.isMobile ? 'list__title-button--large' : ''"
          disabledQuiet
        >
          <slot>
            <span class="rr-button__text">
              {{ $t('searchTitle/allPhotosInCart', [photosTotal]) }}
            </span>
          </slot>
        </Button>
      </span>    
    </div>

    <div v-if="$root.isMobile" class="d-flex justify-space-between">
      <div class="list__count">
        {{ $t('searchTitle/found', [photosTotal]) }}
      </div>
      <div v-if="hasPackageOffers" @click.stop="$emit('offers')" class="list__offers">
        <base-icon width="16" height="16" viewBox="0 0 24 24" class="list__offers-icon">
          <icon-discount2 v-if="theme === ''" fill="var(--color-primary)" />
          <icon-discount v-if="theme !== ''" fill="var(--color-primary)" />
        </base-icon>
        {{ $t('searchTitle/packages') }}
      </div>
    </div>

  </div>
  
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Loader from '@/components/loader.vue';
import GiftLabel from '@/components/gift-label/gift-label.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconReset from '@/components/icons/icon-reset.vue';
import IconShoppingCart from '@/components/icons/icon-shopping-cart.vue';
import IconShoppingCart2 from '@/components/icons/icon-shopping-cart2.vue';
import IconDiscount from '@/components/icons/icon-discount.vue';
import IconDiscount2 from '@/components/icons/icon-discount2.vue';
import Common from '@/plugins/common';

export default {
  name: 'SearchTitle',
  props: {
    faceImage: String,
    runnerNum: String,
    photosTotal: Number,
    photosWithMeProduct: Object,
    isAddingPhotosWithMe: Boolean,
    isPhotosWithMeInCart: Boolean,
    hasPackageOffers: Boolean,
    giftLabel: String,
  },
  components: {
    Loader,
    GiftLabel,
    BaseIcon,
    IconReset,
    IconShoppingCart,
    IconShoppingCart2,
    IconDiscount,
    IconDiscount2,
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
    isAllPhotos() {
      return this.$route.name === 'event-photos';
    },
  },
  methods: {
    formatAmount(amount, onlyInteger = true) {
      return Common.formatAmount(amount, onlyInteger);
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "./search-title";
</style>