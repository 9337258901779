<template>  
  <div class="package-offers" @click.stop="$emit('closed')">
    <div class="package-offers__overlay" @click.stop="$emit('closed')"></div>
    
    <div class="package-offers__modal">
      <div class="package-offers__container" @click.stop>
        <div class="package-offers__header">
          <div class="package-offers__title" v-html="$t('searchTitle/packages')"></div>
          <div class="package-offers__close" @click.stop="$emit('closed')">
            <Button
              variant="clear"
              shape="square"
              size="lg"
              iconLeft="close"
              mobile
              @click="$emit('closed')"
            />        
          </div>
        </div>

        <div class="package-offers__content">
          <PaymentDiscount
            v-if="paymentDiscountItems.length > 0"
            :items="paymentDiscountItems"
            class="discount"
          />

          <div
            v-for="(group, i) in items"
            :key="i"  
            class="content__group"
          >
            <div class="group__header" @click.stop="onToggle(i)">
              <span v-html="$t(group.title, [group.items.length])"></span>
              <Icon :name="state[i] ? 'up' : 'down'" size="l" />
            </div>
            <div v-show="state[i]" class="group__items">
              <PackageOffersItem
                v-for="(item, index) in group.items"
                :key="index"
                v-bind="item"
                @action="$emit('action')"
              ></PackageOffersItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import Vue from 'vue';
import PackageOffersItem from '@/components/package-offers-item/package-offers-item.vue';
import PaymentDiscount from '@/components/payment-discount/payment-discount.vue';

export default {
  name: 'PackageOffers',
  props: {
    items: Array,
    paymentDiscountItems: Array,
  },
  components: {
    PackageOffersItem,
    PaymentDiscount,
  },
  data() {
    return {
      state: [],
    };
  },
  methods: {
    onToggle(index) {
      Vue.set(this.state, index, !this.state[index]);
    },
  },
  mounted() {
    this.state = new Array(this.$props.items.length);
    this.state.fill(true);
  },
};
</script>


<style lang="scss" scoped>
  @import "./package-offers";
</style>
