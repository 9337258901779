<template>
  <div class="gift-label">
    <div class="gift-label__arrow"></div>
    <div class="gift-label__content">
      <div class="gift-label__image">
        <img src="/img/gift-colored.svg" />
      </div>
      <div class="gift-label__text">
        <Link       
          variant="primary"
          size="sm"
          :id="giftDetailsId"
          :text="$t('packageOffers/gift')"
        ></Link> <span v-html="$t('packageOffers/forAll')"></span>
        <Dropdown
          v-if="giftDetailsId"
          :triggerId="giftDetailsId"
          :placement="$root.isMobile || $root.isTablet ? 'bottom-end' : 'bottom-start'"
          listeners="click hover"
          hide-overlay
          auto-width
        >
          <template slot="items">
            <div class="base-dropdown__item">
              <div class="base-dropdown__text">
                <span v-html="tipText"></span>
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import Dropdown from '@/components/dropdown/dropdown.vue';

export default {
  name: 'GiftLabel',
  props: {
    tipText: String,
  },
  components: {
    Dropdown,
  },
  data() {
    return {
      giftDetailsId: `giftDetails${Math.random().toString()}`,
    };
  },
};
</script>


<style lang="scss" scoped>
  @import "./gift-label";
</style>