<template>
  <div class="pagination">
    <div class="pagination__pages">
      <div 
        class="pagination__prev" 
        :class="isPrevButtonActive ? 'pagination__prev--active' : ''"
        @click.stop="onPrev"
      >
        <base-icon v-if="theme === ''" width="16" height="16" viewBox="0 0 24 24">
          <icon-left2 fill="var(--color-black)" :opacity="isPrevButtonActive ? '0.85' : '0.25'" />
        </base-icon>
        <base-icon v-if="theme !== ''" width="16" height="16" viewBox="0 0 20 20">
          <icon-left
            :fill="isPrevButtonActive ? 'var(--color-black)' : 'var(--pagination__pagination__prev-icon-color, var(--color-black, #010101))'"
            :opacity="isPrevButtonActive ? '0.85' : '0.25'"
          />
        </base-icon>
      </div>
      <div class="pages__page">
        <Input
          v-model="pageNumber"
          :size="$root.isMobile ? 'sm' : 'md'"
          :disabled="totalPages === 1"
          :state="isValidPage ? '' : 'error'"
          :noStateIcons="true"
          type="number"
          autocomplete="off"
          mobile
          @keydown="onKeyDown"
          @blur="onBlur"
          class="page__input"
        ></Input>
        <div class="page__of">{{ $t('of') }}</div>
        <div class="page__number">{{ totalPages }}</div>
      </div>
      <div 
        class="pagination__next" 
        :class="isNextButtonActive ? 'pagination__next--active' : ''"
        @click.stop="onNext"
      >
        <base-icon v-if="theme === ''" width="16" height="16" viewBox="0 0 24 24">
          <icon-right2 :fill="isNextButtonActive ? 'var(--color-black-85)' : 'var(--color-black-25)'" />
        </base-icon>
        <base-icon v-if="theme !== ''" width="16" height="16">
          <icon-right
            :fill="isNextButtonActive ? 'var(--color-black)' : 'var(--pagination__pagination__prev-icon-color, var(--color-black, #010101))'"
            :opacity="isNextButtonActive ? '0.85' : '0.25'"
          />        
        </base-icon>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconLeft from "@/components/icons/icon-left.vue"
import IconLeft2 from "@/components/icons/icon-left2.vue";
import IconRight from "@/components/icons/icon-right.vue"
import IconRight2 from "@/components/icons/icon-right2.vue"

export default {
  name: 'Pagination',
  props: {
    currentPage: Number,
    totalPages: Number,
  },
  components: {
    BaseIcon,
    IconLeft,
    IconLeft2,
    IconRight,
    IconRight2,
  },
  data() {
    return {
      nextPage: 0,
      prevPage: 0,
      pageNumber: '',
      isValidPage: true,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
    isPrevButtonActive() {
      return this.$props.currentPage > 1;
    },
    isNextButtonActive() {
      return this.$props.currentPage < this.$props.totalPages;
    },
  },
  watch: {
    '$props.currentPage'() {
      this.pageNumber = this.$props.currentPage;
    },
  },
  methods: {
    isPageValid() {
      if (this.pageNumber.length === 0) return false;
      const value = parseInt(this.pageNumber);
      if (!isFinite(value)) return false;
      if (value < 1 || value > this.$props.totalPages) return false;
      return true;
    },
    changePage() {
      const page = parseInt(this.pageNumber);
      if (this.$props.currentPage === page) return;
      this.$emit('go-page', page);
      this.pageNumber = page.toString();
    },
    async onPrev() {
      if (!this.isPrevButtonActive) return;
      this.$emit('prev-page');
    },
    async onNext() {
      if (!this.isNextButtonActive) return;
      this.$emit('next-page');
    },
    async onKeyDown(event) {
      if (event.key === 'Enter') {
        if (this.isPageValid()) {
          this.changePage();
        } else {
          this.isValidPage = false;
        }
      } else {
        this.isValidPage = true;
      }
    },
    async onBlur() {
      if (this.isPageValid()) {
        this.isValidPage = true;
        this.changePage();
      } else {
        this.isValidPage = false;
      }
    },
  },
  mounted() {
    this.pageNumber = this.$props.currentPage;
  },
};
</script>


<style lang="scss" scoped>
  @import "./pagination";
</style>
