<template>
  <div 
    v-show="snackbar"
    class="snackbar" 
    @click.stop="onClose"
  >
    <div class="snackbar__container">
      <div class="container__icon">
        <Icon name="check-circle-s" size="m" />
      </div>
      <div class="container__text" v-html="text"></div>
    </div>

  </div>
</template>


<script lang="js">
export default {
  name: 'Snackbar',
  props: {
    snackbar: Boolean,
    text: String,
  },
  watch: {
    snackbar() {
      if (this.snackbar) setTimeout(() => { this.onClose(); }, 7000);
    },
  },
  methods: {
    onClose() {
      this.$emit('closed');
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "./snackbar";
</style>