







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconRight2",
  props: {
    fill: {
      type: String,
      default: "white"
    }
  },
})
export default class IconRight2 extends Vue {
}
