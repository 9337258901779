<template>
  <div class="capture-photo">

    <div class="capture-photo__back" @click="onCancel">
      <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 24 24">
        <icon-left2 fill="var(--color-white)" opacity="1" />
      </base-icon>
      <base-icon v-if="theme !== ''" width="20" height="20" viewBox="0 0 20 20">
        <icon-left fill="var(--color-white)" opacity="1" />
      </base-icon>
    </div>

    <div v-show="mode == 'searching'">
      <div class="capture-photo__icon">
        <Loader size="24" />
      </div>

      <div class="capture-photo__searching">
        {{ $t('capturePhoto/searchingText') }}
        <div class="searching__time">
          {{ $t('capturePhoto/searchingTime') }}
        </div>
      </div>
    </div>

    <div v-show="mode == 'many-people' || mode == 'no-face'" class="capture-photo__message-container">
      <div class="capture-photo__message d-flex flex-column align-center justify-center">
        <div class="message__content">
          <div v-show="mode == 'many-people'" class="content__header text-no-wrap">
            {{ $t('capturePhoto/manyPeopleTitle') }}
          </div>
          <div v-show="mode == 'many-people'" class="content__text">
            {{ $t('capturePhoto/manyPeopleText') }}
          </div>
          <div v-show="mode == 'no-face'" class="content__header text-no-wrap">
            {{ $t('capturePhoto/noFaceTitle') }}
          </div>
          <div v-show="mode == 'no-face'" class="content__text">
            {{ $t('capturePhoto/noFaceText') }}
          </div>
        </div>
        
        <Button 
          @click.stop="onSearchByFace"
          variant="primary" 
          :wide="true"
          size="lg"
          class="message__selfie"
        >
          <slot>
            <span class="rr-button__text">{{ $t('capturePhoto/takeSelfie') }}</span>
          </slot>
        </Button>

        
        <div class="message__divider d-flex justify-space-between">
          <div class="divider__line"></div>
          <div class="divider__text">{{ $t('capturePhoto/or') }}</div>
          <div class="divider__line"></div>
        </div>

        <Button 
          @click.stop="onUpload"
          variant="ghost" 
          view="light"
          :wide="true"
          size="lg"
          class="message__upload"
        >
          <slot>
            <span class="rr-button__text">{{ $t('capturePhoto/uploadPhoto') }}</span>
          </slot>
        </Button>
      </div>
    </div>

    <div v-show="mode == 'too-big-file' || mode == 'error'" class="capture-photo__message-container">
      <div class="capture-photo__message d-flex flex-column align-center justify-center">
        <div class="message__content">
          <div v-show="mode == 'too-big-file'" class="content__header text-no-wrap">
            {{ $t('capturePhoto/tooBigFileTitle') }}
          </div>
          <div v-show="mode == 'too-big-file'" class="content__text">
            {{ $t('capturePhoto/tooBigFileText') }}
          </div>
          <div v-show="mode == 'error'" class="content__header text-no-wrap">
            {{ $t('capturePhoto/commonErrorTitle') }}
          </div>
          <div v-show="mode == 'error'" class="content__text">
            {{ $t('capturePhoto/commonErrorText') }}
          </div>
        </div>

        <Button 
          v-if="mode == 'too-big-file'"
          @click.stop="onSearchByFace"
          variant="primary" 
          :wide="true"
          size="lg"
          class="message__selfie"
        >
          <slot>
            <span class="rr-button__text">{{ $t('capturePhoto/takeSelfie') }}</span>
          </slot>
        </Button>
        
        <div v-if="mode == 'too-big-file'" class="message__divider d-flex justify-space-between">
          <div class="divider__line"></div>
          <div class="divider__text">{{ $t('capturePhoto/or') }}</div>
          <div class="divider__line"></div>
        </div>

        <Button 
          v-if="mode == 'too-big-file'"
          @click.stop="onUpload"
          variant="ghost" 
          view="light"
          :wide="true"
          size="lg"
          class="message__upload"
        >
          <slot>
            <span class="rr-button__text">{{ $t('capturePhoto/uploadPhoto') }}</span>
          </slot>
        </Button>

        <Button 
          v-if="mode == 'error'"
          @click.stop="onCancel"
          variant="primary" 
          :wide="true"
          size="lg"
          class="message__selfie"
        >
          <slot>
            <span class="rr-button__text">{{ $t('close') }}</span>
          </slot>
        </Button>        
      </div>
    </div>

    <div v-show="mode == 'not-found'" class="capture-photo__message-container">
      <div class="capture-photo__message d-flex flex-column align-center justify-center">
        <Alert
          :title="$t('searchResults/photoNotFound')"
          :text="$t('findMore/notFoundByFace')"
          state="warning"
          noAction
        ></Alert>        
        
        <Button 
          @click.stop="onSearchByFace"
          variant="primary" 
          size="lg"
          class="message__selfie message__selfie--other"
          mobile
        >
          <slot>
            <span class="rr-button__text">
              {{ $t('searchResults/searchByAnother') }} {{ $t('searchResults/facePhoto') }}
            </span>
          </slot>
        </Button>
      </div>
    </div>

    <div class="capture-photo__image"> 
      <div class="capture-photo__container">

        <Row class="capture-photo__row">
          <Col class="capture-photo__col">
            
            <div v-if="!theme" class="capture-photo__overlay overlay--neutral"></div>
            <div class="capture-photo__overlay overlay--gradient"></div>
            <div id="capture-photo__photo" class="capture-photo__photo">
            </div>

          </Col>
        </Row>

      </div>
    </div>

  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Loader from '@/components/loader.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconLeft from '@/components/icons/icon-left.vue';
import IconLeft2 from '@/components/icons/icon-left2.vue';

export default {
  name: 'CapturePhoto',
  props: {
    redraw: String,
    mode: String,
  },
  components: {
    Loader,
    BaseIcon,
    IconLeft,
    IconLeft2,
  },
  data() {
    return {
      form: null,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
    ...mapGetters('CaptureState', ['file']),
    redrawImage() {
      return this.$props.redraw === 'true';
    },
    dialogMode() {
      return this.$props.mode;
    },
  },
  watch: {
    redrawImage() {
      if (this.$store.state.selectedFile === null) return;
      this.loadImage();
    },
    dialogMode() {
      if (this.dialogMode.length === 0) {
        this.form.reset();
        this.$emit('cancelled');
      }
    },
  },
  methods: {
    onSearchByFace() {
      this.form.reset();
      this.$emit('searchSelfie');
    },
    onUpload() {
      this.form.reset();
      this.$emit('searchUpload');
    },
    onCancel() {
      this.form.reset();
      this.$emit('cancelled');
    },
    loadImage() {
      const file = this.file;
      
      if (file !== null) {
        const im = document.getElementById("capture-photo__photo")
        const imgURL = URL.createObjectURL(file);

        if (im) {
          im.setAttribute("style", `background-image: url('${ imgURL }');`);
        }
      }
    },
  },
  mounted() {
    const form = document.getElementById("cameraForm");
    if (!form) return;
    this.form = form;
    
    if (this.$store.state.selectedFile === null) return;

    this.loadImage();
  }

}
</script>


<style lang="scss" scoped>
  @import "./capture-photo";
</style>