







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconCloseCircleS",
})
export default class IconCloseCircleS extends Vue {
}
