<template>
  <div class="find-more">
    <div class="find-more__title">
      <div>
        <span v-html="$t('findMore/title')"></span>
      </div>
    </div>
    <div class="find-more__tabs"></div>
    <div v-if="false" class="find-more__divider"></div>
    <div class="find-more__content">
      <div v-if="!showResults" class="find-more__form">
        <div v-if="!moreByPhoto" class="start-number__label">{{ $t('findMore/numberLabel') }}</div>
        <Input
          v-if="!moreByPhoto"
          v-model.trim="startNumber"
          placeholder="00000"
          class=""
          :state="showNotFound ? 'error' : ''"
          :disabled="searching"
          mobile
          autocomplete="off"
          noStateIcons
          @keydown="onKeyDown"
        ></Input>
        <Alert
          v-if="showNotFound && !searching"
          :text="$t('findMore/notFoundByNumber')"
          state="error"
          class="find-more__not-found"
          noIcon
          noAction
        ></Alert>
        <Alert
          v-if="showError"
          :title="$t('capturePhoto/commonErrorTitle')"
          :text="$t('capturePhoto/commonErrorText')"
          state="error"
          class="find-more__not-found"
          noIcon
          noAction
        ></Alert>
        <Button 
          @click.stop="onSearch"
          variant="primary"
          :iconRight="moreByPhoto ? 'selfie' : ''"
          :wide="moreByPhoto ? false : true"
          :disabled="moreByPhoto ? searching : !startNumber || searching"
          size="lg"
          class=""
          mobile
        >
          <slot name="before">
            <Loader v-if="searching" size="18" fill="rgba(255, 255, 255, 0.87)" />
          </slot>
          <slot v-if="!searching">
            <span class="rr-button__text">
              {{ moreByPhoto ? $t('searchForm/searchByFace') : $t('findMore/buttonFind') }}
            </span>
          </slot>
        </Button>
      </div>

      <div v-if="showResults" class="find-more__header">
        <div
          @click.stop="$emit('backtosearch')"
          class="header__search"
          :class="faceImage ? 'header__search--selfie' : ''"
        >
          <div
            v-if="faceImage" 
            :style="{'background-image': 'url(' + faceImage + ')'}"
            class="header__selfie" 
          >
          </div>
          <span v-if="!faceImage">
            {{ $t('searchTitle/number') + startNumber }}
          </span>
          <div class="header__icon">
            <Icon name="close" size="s"></Icon>
          </div>
        </div>
        <div v-if="showResults" class="header__count">
          {{ $t('searchTitle/found', [results.length]) }}
        </div>
      </div>

      <div v-if="showResults" class="find-more__results">
        <div 
          class="results-grid"
          ref="searchMoreResultsGrid"
        >
          <div class="results-grid__sizer"></div>
          <div 
            class="results-grid__item"
            v-for="(item, index) in results"
            :key="index"
          >
            <PossibleMatch
              v-bind="{
                ...item,
                itIsMe: getItIsMe(item),
                showFrameAction,
              }"
              @show="$emit('show', index)"
              @frame="$emit('frame', { photoId: item.photoId, productIndex: 0 })"
              @itsme="$emit('itsme', item)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import Masonry from "masonry-layout";
import Loader from "@/components/loader.vue";
import PossibleMatch from '@/components/possible-match/possible-match.vue';

export default {
  name: 'FindMore',
  props: {
    moreByPhoto: {
      type: Boolean,
      required: true,
    },
    showResults: {
      type: Boolean,
      required: true,
    },
    showNotFound: {
      type: Boolean,
    },
    showError: {
      type: Boolean,
    },
    searching: {
      type: Boolean,
    },
    showFrameAction: {
      type: Boolean,
    },
    faceImage: {
      type: String,
    },
    runnerNum: {
      type: String,
    },
    results: {
      type: Array,
    },
    itsMePhotos: {
      type: Array,
    }
  },
  components: {
    Loader,
    PossibleMatch,
  },
  data() {
    return {
      startNumber: '',
      selfieImage: '',
      resultsGrid: null,
    };
  },
  watch: {
    showResults(val) {
      if (val) {
        this.createMasonry();
      }
    },
    faceImage() {
      this.setValues();
    },
    runnerNum() {
      this.setValues();
    },
  },
  methods: {
    onSearch() {
      const payload = this.$props.moreByPhoto ? { faceImage: 'true' } : { startNumber: this.startNumber };
      this.$emit('search', payload);
    },
    onKeyDown(event) {
      if (event.key === 'Enter' && this.startNumber !== '') {
        this.onSearch();
        return;
      }
      if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        return;
      }
      const template = /[0-9\-_]/;
      if (!template.test(event.key) || this.startNumber.length === 16) {
        event.preventDefault();
        return;
      }
    },
    setValues() {
      this.startNumber = this.$props.runnerNum;
      this.selfieImage = this.$props.faceImage;
    },
    getItIsMe(item) {
      return this.$props.itsMePhotos.includes(item.photoId);
    },
    createMasonry() {
      const vm = this;
      this.$nextTick(() => { 
        if (vm.resultsGrid === null && vm.results.length > 0) {
          setTimeout(() => { vm.initMasonry() }, 1000);
        }
      });
    },
    initMasonry() {
      const resultsGrid = this.$refs.searchMoreResultsGrid;
      if (resultsGrid) {
        this.resultsGrid = new Masonry(resultsGrid, {
          itemSelector: ".results-grid__item",
          columnWidth: ".results-grid__sizer",
          percentPosition: true,
        });

        const vm = this;
        let resizeTimeout = null;

        window.addEventListener('resize', () => {
          clearTimeout(resizeTimeout);
          resizeTimeout = setTimeout(vm.reloadResultsGrid, 400);
        });
      }
    },
    reloadResultsGrid() {
      if (this.$route.name != 'search' && this.$route.name != 'search-from-url')
        return;

      if (this.resultsGrid) {
        this.resultsGrid.layout();
      }
    },
  },
  mounted() {
    this.setValues();
  },
};
</script>


<style lang="scss" scoped>
  @import "./find-more";
</style>
