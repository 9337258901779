<template>
  <div
    class="package-offers-banner"
    :class="{'package-offers-banner_vertical': isVertical}"
  >
    <div class="package-offers-banner__content">
      <div
        class="package-offers-banner__title"
        v-html="$t('ssb/bannerPackagesTitle', [
          formatAmount(allPhotosDiscount, true),
        ])"
      ></div>
      <div
        class="package-offers-banner__subtitle"
        v-html="subtitle"
      ></div>
      <Button
        class="package-offers-banner__button"
        variant="primary"
        :text="$t('learnMore')"
        mobile
        @click="$emit('click:more')"
      ></Button>
    </div>
    <svg class="package-offers-banner__img" width="360" height="240" viewBox="0 0 360 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_13_23182)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7489 203.07C36.1698 169.449 42.5563 135.175 67.714 111.669C92.1035 88.8807 126.587 88.7533 159.115 81.2624C209.125 69.7453 261.212 30.5572 305.209 56.9756C351.772 84.9345 362.613 148.762 361.974 203.07C361.342 256.728 345.438 314.52 301.81 345.765C261.602 374.56 208.57 348.269 159.115 348.352C109.495 348.436 49.0766 383.178 15.9375 346.247C-17.3304 309.173 18.0126 251.711 28.7489 203.07Z" fill="#F2F2F2"/>
      <g clip-path="url(#clip1_13_23182)">
      <path d="M53.6061 103.395C50.8595 103.398 48.2264 104.491 46.2843 106.433C44.3422 108.375 43.2498 111.008 43.2467 113.754V157.687C43.2498 160.433 44.3422 163.066 46.2843 165.008C48.2264 166.95 50.8595 168.043 53.6061 168.046H121.782C124.529 168.043 127.162 166.95 129.104 165.008C131.046 163.066 132.138 160.433 132.142 157.687V113.754C132.138 111.008 131.046 108.375 129.104 106.433C127.162 104.491 124.529 103.398 121.782 103.395H53.6061Z" fill="white"/>
      <mask id="mask0_13_23182" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="51" y="111" width="74" height="45">
      <path d="M124.365 112.597V154.556C124.365 154.695 124.338 154.832 124.285 154.96C124.232 155.089 124.154 155.205 124.056 155.303C123.958 155.401 123.842 155.479 123.713 155.532C123.585 155.585 123.448 155.613 123.309 155.613H52.0798C52.0291 155.613 51.9784 155.609 51.9284 155.601C51.6771 155.565 51.4473 155.439 51.2811 155.247C51.1149 155.055 51.0234 154.81 51.0234 154.556V112.597C51.0233 112.458 51.0506 112.321 51.1036 112.193C51.1567 112.064 51.2344 111.948 51.3324 111.85C51.4305 111.752 51.5468 111.674 51.6749 111.621C51.8031 111.568 51.9404 111.54 52.079 111.54H123.309C123.447 111.54 123.585 111.568 123.713 111.621C123.841 111.674 123.957 111.751 124.055 111.849C124.154 111.947 124.231 112.064 124.284 112.192C124.338 112.32 124.365 112.458 124.365 112.597Z" fill="#E6E6E6"/>
      </mask>
      <g mask="url(#mask0_13_23182)">
      <path d="M124.365 112.597V154.556C124.365 154.695 124.338 154.832 124.285 154.96C124.232 155.089 124.154 155.205 124.056 155.303C123.958 155.401 123.842 155.479 123.713 155.532C123.585 155.585 123.448 155.613 123.309 155.613H52.0798C52.0291 155.613 51.9784 155.609 51.9284 155.601C51.6771 155.565 51.4473 155.439 51.2811 155.247C51.1149 155.055 51.0234 154.81 51.0234 154.556V112.597C51.0233 112.458 51.0506 112.321 51.1036 112.193C51.1567 112.064 51.2344 111.948 51.3324 111.85C51.4305 111.752 51.5468 111.674 51.6749 111.621C51.8031 111.568 51.9404 111.54 52.079 111.54H123.309C123.447 111.54 123.585 111.568 123.713 111.621C123.841 111.674 123.957 111.751 124.055 111.849C124.154 111.947 124.231 112.064 124.284 112.192C124.338 112.32 124.365 112.458 124.365 112.597Z" fill="#E6E6E6"/>
      <g opacity="0.45">
      <path d="M57.9468 145.055C65.2763 145.055 71.2181 139.113 71.2181 131.784C71.2181 124.454 65.2763 118.513 57.9468 118.513C50.6173 118.513 44.6756 124.454 44.6756 131.784C44.6756 139.113 50.6173 145.055 57.9468 145.055Z" fill="#CCCCCC"/>
      <path opacity="0.2" d="M48.1478 122.919C46.9099 125.815 46.742 129.057 47.6741 132.065C48.6061 135.073 50.5774 137.652 53.2357 139.34C55.894 141.028 59.0661 141.717 62.1851 141.282C65.3041 140.847 68.1668 139.317 70.2617 136.965C69.5054 138.734 68.3736 140.318 66.9447 141.606C65.5157 142.895 63.8237 143.857 61.9859 144.426C60.1481 144.996 58.2085 145.159 56.3015 144.904C54.3944 144.65 52.5654 143.984 50.9414 142.952C49.3173 141.921 47.9369 140.548 46.8959 138.93C45.8548 137.312 45.178 135.487 44.9122 133.582C44.6465 131.676 44.7982 129.736 45.3569 127.895C45.9156 126.054 46.8679 124.356 48.1478 122.919Z" fill="black"/>
      <path d="M57.9832 131.783H58.0199L58.676 169.409H57.2906L57.9832 131.783Z" fill="#3F3D56"/>
      <path d="M115.043 132.95C123.871 132.95 131.028 125.793 131.028 116.965C131.028 108.137 123.871 100.98 115.043 100.98C106.215 100.98 99.058 108.137 99.058 116.965C99.058 125.793 106.215 132.95 115.043 132.95Z" fill="var(--color-primary)"/>
      <path opacity="0.2" d="M126.846 106.288C128.337 109.776 128.539 113.68 127.416 117.303C126.294 120.927 123.919 124.033 120.718 126.066C117.516 128.1 113.695 128.929 109.938 128.405C106.181 127.881 102.733 126.038 100.21 123.206C101.121 125.337 102.484 127.244 104.205 128.796C105.926 130.348 107.964 131.507 110.178 132.193C112.392 132.879 114.728 133.075 117.025 132.769C119.322 132.462 121.525 131.66 123.481 130.417C125.437 129.175 127.1 127.522 128.354 125.573C129.608 123.624 130.423 121.426 130.743 119.131C131.063 116.835 130.88 114.498 130.207 112.281C129.534 110.063 128.387 108.018 126.846 106.288Z" fill="black"/>
      <path d="M114.999 116.966H114.955L114.165 162.286H115.833L114.999 116.966Z" fill="#3F3D56"/>
      </g>
      <path d="M86.3389 130.604C89.5077 130.604 92.0766 128.197 92.0766 125.229C92.0766 122.261 89.5077 119.854 86.3389 119.854C83.17 119.854 80.6011 122.261 80.6011 125.229C80.6011 128.197 83.17 130.604 86.3389 130.604Z" fill="#2F2E41"/>
      <path d="M77.1875 135.615C77.1875 135.615 75.2992 135.615 74.8634 138.23C74.4276 140.844 73.9918 149.56 73.9918 149.56C73.9918 149.56 67.891 157.549 68.9078 158.566C69.9246 159.583 71.0867 161.471 71.8129 161.18C72.5392 160.89 78.7854 152.32 78.7854 152.32L77.1875 135.615Z" fill="var(--color-primary)"/>
      <path opacity="0.1" d="M77.1875 135.615C77.1875 135.615 75.2992 135.615 74.8634 138.23C74.4276 140.844 73.9918 149.56 73.9918 149.56C73.9918 149.56 67.891 157.549 68.9078 158.566C69.9246 159.583 71.0867 161.471 71.8129 161.18C72.5392 160.89 78.7854 152.32 78.7854 152.32L77.1875 135.615Z" fill="black"/>
      <path d="M82.9979 128.207C82.9979 128.207 82.8526 133.872 81.1095 134.162C79.3664 134.453 79.9475 136.341 79.9475 136.341C79.9475 136.341 85.6125 139.682 89.3893 136.341L90.1156 134.598C90.1156 134.598 87.5009 132.71 88.663 130.095L82.9979 128.207Z" fill="#FFB8B8"/>
      <path d="M86.6293 131.693C89.357 131.693 91.5681 129.482 91.5681 126.754C91.5681 124.026 89.357 121.815 86.6293 121.815C83.9017 121.815 81.6906 124.026 81.6906 126.754C81.6906 129.482 83.9017 131.693 86.6293 131.693Z" fill="#FFB8B8"/>
      <path d="M89.4619 133.654L87.9366 135.179C87.9366 135.179 83.2884 136.922 81.1095 135.179C81.1095 135.179 81.9084 133.509 81.9084 133.654C81.9084 133.799 75.5896 134.743 75.0086 137.358C74.4276 139.973 75.4444 144.476 75.4444 144.476C75.4444 144.476 71.3771 163.65 72.975 164.086C74.5728 164.521 89.5345 168.153 89.3892 165.393C89.244 162.633 90.5513 151.303 90.5513 151.303L95.6353 141.571C95.6353 141.571 94.9091 138.375 93.1659 137.358C91.4228 136.341 89.4619 133.654 89.4619 133.654Z" fill="var(--color-primary)"/>
      <path opacity="0.1" d="M93.747 140.699L95.0543 142.006C95.0543 142.006 95.9259 154.208 94.1828 156.677C92.4397 159.147 84.4504 165.829 84.0147 165.393C83.5789 164.957 80.819 162.778 81.4 162.488C81.981 162.197 89.7063 154.125 89.7063 154.125L89.0987 142.878L93.747 140.699Z" fill="black"/>
      <path d="M94.328 140.263L95.6354 141.571C95.6354 141.571 96.5069 153.772 94.7638 156.242C93.0207 158.711 85.0315 165.393 84.5957 164.957C84.1599 164.521 81.4 162.343 81.9811 162.052C82.5621 161.761 90.2874 153.69 90.2874 153.69L89.6798 142.442L94.328 140.263Z" fill="var(--color-primary)"/>
      <path d="M84.5868 119.739C84.5868 119.739 82.908 119.128 80.6186 120.426L81.8396 120.731C81.8396 120.731 80.0082 120.884 79.7792 123.325H80.6186C80.6186 123.325 80.0845 125.462 80.6186 126.073L80.962 125.424L81.9541 127.408L82.183 126.416L82.6409 126.569L82.9461 124.966C82.9461 124.966 83.7092 126.111 84.3197 126.187V125.195C84.3197 125.195 85.9985 127.103 86.5327 127.027L85.7696 125.958L86.8379 126.187L86.3801 125.424L89.1272 126.187L88.5168 125.424L90.4245 126.035L91.3402 126.416C91.3402 126.416 92.6375 123.44 90.8061 121.38C88.9746 119.319 86.3038 118.861 84.5868 119.739Z" fill="#2F2E41"/>
      </g>
      <path d="M121.782 168.45H53.6061C50.7524 168.447 48.0166 167.312 45.9987 165.294C43.9809 163.276 42.8459 160.54 42.8427 157.687V113.755C42.8459 110.901 43.9809 108.165 45.9987 106.147C48.0166 104.129 50.7524 102.994 53.6061 102.991H121.782C124.636 102.994 127.372 104.129 129.39 106.147C131.407 108.165 132.542 110.901 132.546 113.755V157.687C132.542 160.54 131.407 163.276 129.39 165.294C127.372 167.312 124.636 168.447 121.782 168.45ZM53.6061 103.799C50.9667 103.802 48.4363 104.852 46.5699 106.718C44.7036 108.585 43.6538 111.115 43.6508 113.755V157.687C43.6538 160.326 44.7036 162.856 46.5699 164.723C48.4363 166.589 50.9667 167.639 53.6061 167.642H121.782C124.422 167.639 126.952 166.589 128.818 164.723C130.685 162.856 131.735 160.326 131.738 157.687V113.755C131.735 111.115 130.685 108.585 128.818 106.718C126.952 104.852 124.422 103.802 121.782 103.799H53.6061Z" fill="#3F3D56"/>
      <path d="M270.291 237.069L259.38 244.164L259.29 244.026C258.558 242.899 258.303 241.528 258.582 240.214C258.86 238.899 259.649 237.75 260.776 237.017L260.776 237.017L261.786 234.209L266.488 233.303L267.44 232.684L270.291 237.069Z" fill="#2F2E41"/>
      <path d="M206.951 239.494L202.719 239.494L200.706 223.168L206.952 223.169L206.951 239.494Z" fill="#FFB6B6"/>
      <path d="M207.1 243.909L194.085 243.909V243.744C194.085 242.401 194.619 241.112 195.569 240.162C196.519 239.212 197.808 238.678 199.151 238.678H199.151L201.529 236.875L205.964 238.679L207.101 238.679L207.1 243.909Z" fill="#2F2E41"/>
      <path d="M250.76 138.759L212.807 139.593L209.045 144.296C209.045 144.296 204.608 146.61 204.319 148.431C204.029 150.252 201.469 182.587 202.853 183.805C204.238 185.023 200.229 193.43 199.935 193.943C199.64 194.455 196.467 219.762 199.935 221.843L201.237 230.677H207.298C207.298 230.677 213.37 223.65 212.148 222.376C210.926 221.103 214.421 216.867 214.555 214.596C214.688 212.325 215.964 202.023 214.975 200.372C213.987 198.72 212.65 192.521 215.158 190.118C217.666 187.715 229.363 158.185 229.363 158.185C229.363 158.185 232.762 192.804 232.754 195.523C232.744 199.058 235.066 197.308 235.066 200.412C235.066 201.039 242.625 217.154 247.705 222.191L258.211 230.273L263.06 226.636V221.383C263.06 221.383 258.566 211.637 256.999 210.069C255.431 208.502 249.643 203.704 249.643 201.509C249.643 199.315 246.919 198.55 248.751 195.641C250.584 192.731 249.957 191.477 249.33 190.223C248.703 188.969 255.913 149.468 255.913 149.468L250.76 138.759Z" fill="#2F2E41"/>
      <path d="M243.574 67.0804L227.107 66.2892L222.893 73.2023C222.893 73.2023 210.585 74.4376 212.345 79.0667C214.105 83.6958 215.633 111.182 213.145 115.152C210.657 119.121 206.838 115.831 210.158 123.589C213.478 131.348 206.09 144.998 206.09 144.998L227.586 149.531C227.586 149.531 244.828 144.515 247.963 141.38C251.098 138.245 253.92 135.737 252.039 134.796C250.158 133.856 244.828 133.229 247.65 128.84C250.471 124.451 250.816 124.654 250.8 121.731C250.785 118.808 249.136 111.565 249.136 111.565C249.136 111.565 259.957 80.8736 256.697 79.7147C253.437 78.5559 244.337 73.2023 244.337 73.2023L243.574 67.0804Z" fill="var(--color-primary)"/>
      <path d="M190.282 89.6031C192.553 88.4852 192.783 84.3055 190.796 80.2674C188.809 76.2294 185.356 73.8622 183.085 74.9801C180.813 76.098 180.583 80.2777 182.571 84.3158C184.558 88.3538 188.01 90.721 190.282 89.6031Z" fill="#FFB6B6"/>
      <path d="M222.265 76.4951C222.265 76.4951 212.82 72.007 211.427 78.1666C210.034 84.3263 204.656 99.7787 204.656 99.7787C204.656 99.7787 198.699 96.3298 199.64 94.4493C200.582 92.5688 195.484 92.283 197.132 89.1198C198.78 85.9566 191.176 82.8498 191.176 82.8498L182.084 86.6118C182.084 86.6118 185.605 91.888 187.944 94.2142C190.282 96.5404 186.788 96.9558 189.608 99.7788C192.428 102.602 198.107 113.162 198.107 113.162L205.5 116.245L226.265 103.559L222.265 76.4951Z" fill="var(--color-primary)"/>
      <path d="M234.935 62.5734C241.269 62.5734 246.403 57.4387 246.403 51.1047C246.403 44.7708 241.269 39.6361 234.935 39.6361C228.601 39.6361 223.466 44.7708 223.466 51.1047C223.466 57.4387 228.601 62.5734 234.935 62.5734Z" fill="#FFB6B6"/>
      <path d="M242.406 54.0514C242.95 53.7744 243.645 54.2584 243.775 54.8553C243.813 55.159 243.788 55.4673 243.703 55.7612C243.617 56.0551 243.472 56.3284 243.278 56.5644C242.886 57.0333 242.535 57.5348 242.229 58.0633C242.079 58.3306 242.009 58.6351 242.027 58.941C242.044 59.2468 242.149 59.5412 242.328 59.7896C242.524 59.9798 242.769 60.1106 243.036 60.1669C243.303 60.2232 243.58 60.2026 243.836 60.1076C244.344 59.9087 244.794 59.5837 245.143 59.163C247.335 56.7842 249.622 52.8352 248.502 49.8004C248.409 49.476 248.372 49.1382 248.392 48.8014C248.371 48.4153 248.299 47.8336 248.663 47.7023C249.678 47.3358 246.83 41.4031 242.435 40.1124C242.649 38.0868 232.074 35.173 232.651 37.7161C232.755 38.1728 231.734 37.9246 231.266 37.9499C227.678 38.144 223.626 39.0456 223.953 43.204C224.08 44.8144 223.413 44.4429 222.879 45.9671C223.712 46.4638 223.985 47.553 224.627 46.8261C225.27 46.0992 225.818 45.1889 226.739 44.8836C227.719 44.5585 228.783 45.0448 229.619 45.6511C230.455 46.2574 231.222 47.0107 232.205 47.3283C233.307 47.6845 234.509 47.4367 235.659 47.5808C237.292 47.7857 238.743 48.7842 239.823 50.0274C240.852 51.3151 241.721 52.7232 242.41 54.2208" fill="#2F2E41"/>
      <path d="M245.538 143.802C242.792 143.805 240.159 144.897 238.216 146.839C236.274 148.782 235.182 151.415 235.179 154.161V198.093C235.182 200.84 236.274 203.473 238.216 205.415C240.159 207.357 242.792 208.45 245.538 208.453H313.714C316.461 208.45 319.094 207.357 321.036 205.415C322.978 203.473 324.071 200.84 324.074 198.093V154.161C324.071 151.415 322.978 148.782 321.036 146.839C319.094 144.897 316.461 143.805 313.714 143.802H245.538Z" fill="white"/>
      <mask id="mask1_13_23182" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="243" y="151" width="74" height="46">
      <path d="M316.378 153.016V194.976C316.378 195.115 316.351 195.252 316.298 195.38C316.245 195.508 316.167 195.625 316.069 195.723C315.971 195.821 315.854 195.899 315.726 195.952C315.598 196.005 315.461 196.032 315.322 196.032H244.093C244.042 196.033 243.991 196.029 243.941 196.021C243.69 195.984 243.46 195.859 243.294 195.667C243.128 195.475 243.036 195.23 243.036 194.976V153.016C243.036 152.878 243.063 152.74 243.117 152.612C243.17 152.484 243.247 152.368 243.345 152.27C243.443 152.172 243.56 152.094 243.688 152.041C243.816 151.987 243.953 151.96 244.092 151.96H315.321C315.46 151.96 315.597 151.987 315.726 152.04C315.854 152.093 315.97 152.171 316.068 152.269C316.166 152.367 316.244 152.484 316.297 152.612C316.35 152.74 316.378 152.878 316.378 153.016Z" fill="#E6E6E6"/>
      </mask>
      <g mask="url(#mask1_13_23182)">
      <path d="M316.378 153.016V194.976C316.378 195.115 316.351 195.252 316.298 195.38C316.245 195.508 316.167 195.625 316.069 195.723C315.971 195.821 315.854 195.899 315.726 195.952C315.598 196.005 315.461 196.032 315.322 196.032H244.093C244.042 196.033 243.991 196.029 243.941 196.021C243.69 195.984 243.46 195.859 243.294 195.667C243.128 195.475 243.036 195.23 243.036 194.976V153.016C243.036 152.878 243.063 152.74 243.117 152.612C243.17 152.484 243.247 152.368 243.345 152.27C243.443 152.172 243.56 152.094 243.688 152.041C243.816 151.987 243.953 151.96 244.092 151.96H315.321C315.46 151.96 315.597 151.987 315.726 152.04C315.854 152.093 315.97 152.171 316.068 152.269C316.166 152.367 316.244 152.484 316.297 152.612C316.35 152.74 316.378 152.878 316.378 153.016Z" fill="#E6E6E6"/>
      <g opacity="0.25">
      <path d="M260.991 176.912C266.11 176.912 270.259 172.763 270.259 167.644C270.259 162.526 266.11 158.376 260.991 158.376C255.873 158.376 251.723 162.526 251.723 167.644C251.723 172.763 255.873 176.912 260.991 176.912Z" fill="var(--color-primary)"/>
      <path d="M290.054 196.192H244.589C244.532 196.193 244.475 196.189 244.419 196.179L265.922 158.933C266.053 158.701 266.244 158.508 266.474 158.374C266.704 158.239 266.966 158.169 267.232 158.169C267.499 158.169 267.761 158.239 267.991 158.374C268.221 158.508 268.412 158.701 268.543 158.933L282.974 183.928L283.665 185.124L290.054 196.192Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M290.054 196.198H274.531L282.151 185.13L282.7 184.333L282.974 183.934L283.665 185.13L290.054 196.198Z" fill="black"/>
      <path d="M315.369 196.182H276.045L283.665 185.114L284.214 184.317L294.143 169.892C294.794 168.947 296.36 168.888 297.126 169.714C297.177 169.77 297.225 169.83 297.269 169.892L315.369 196.182Z" fill="#3F3D56"/>
      </g>
      <path d="M301.643 187.443L303.398 188.78L305.515 189.43C305.515 189.43 306.39 201.739 307.178 202.528C307.967 203.316 307.256 203.337 307.414 204.362C307.573 205.386 307.958 207.485 307.958 207.485C302.635 210.303 298.047 210.378 294.361 207.082C294.314 206.878 294.338 206.664 294.429 206.475C294.52 206.287 294.673 206.135 294.862 206.045C295.548 205.681 294.23 203.983 294.791 203.699C295.351 203.415 294.563 200.951 294.563 200.951L295.08 195.81L294.671 194.988L295.669 189.96L297.399 189.328L298.09 187.662L301.643 187.443Z" fill="var(--color-primary)"/>
      <path d="M302.742 175.951C302.735 176.173 302.776 176.395 302.862 176.599C302.949 176.804 303.08 176.987 303.245 177.136C303.411 177.285 303.607 177.395 303.82 177.46C304.032 177.525 304.257 177.542 304.477 177.511L306.951 182.319L308.329 179.87L305.768 175.68C305.691 175.314 305.483 174.988 305.181 174.766C304.88 174.543 304.508 174.438 304.135 174.472C303.762 174.505 303.414 174.674 303.158 174.947C302.901 175.219 302.753 175.577 302.742 175.951H302.742Z" fill="#FFB6B6"/>
      <path d="M306.908 177.127L312.213 185.35L312.196 185.388C312.176 185.431 310.975 188.026 309.81 190.402C309.631 190.768 309.453 191.129 309.28 191.475C309.205 191.624 309.131 191.77 309.059 191.913C308.57 192.876 308.143 193.673 307.888 194.052C307.324 194.907 306.484 195.544 305.508 195.857L305.484 195.863L305.46 195.853C304.983 195.652 304.588 195.294 304.342 194.838C304.095 194.382 304.011 193.856 304.104 193.346C304.564 190.887 305.59 188.569 307.099 186.574L307.959 185.441L304.97 178.942L306.908 177.127Z" fill="var(--color-primary)"/>
      <path d="M297.742 174.944C297.75 175.166 297.709 175.387 297.622 175.592C297.535 175.797 297.404 175.98 297.239 176.129C297.073 176.278 296.877 176.388 296.665 176.453C296.452 176.518 296.228 176.535 296.007 176.504L293.534 181.312L292.156 178.863L294.716 174.673C294.793 174.307 295.002 173.981 295.303 173.758C295.604 173.536 295.976 173.431 296.349 173.464C296.722 173.498 297.07 173.667 297.327 173.94C297.583 174.212 297.731 174.57 297.742 174.944H297.742Z" fill="#FFB6B6"/>
      <path d="M293.576 176.12L288.271 184.343L288.288 184.38C288.308 184.424 289.51 187.019 290.674 189.395C290.853 189.761 291.032 190.122 291.205 190.467C291.279 190.617 291.353 190.763 291.425 190.905C291.914 191.869 292.341 192.666 292.596 193.045C293.16 193.9 294 194.537 294.976 194.85L295.001 194.856L295.024 194.846C295.501 194.645 295.896 194.287 296.143 193.831C296.389 193.375 296.473 192.849 296.38 192.339C295.92 189.88 294.895 187.562 293.385 185.567L292.525 184.434L295.514 177.935L293.576 176.12Z" fill="var(--color-primary)"/>
      <path d="M299.846 186.699C301.763 186.699 303.318 185.145 303.318 183.227C303.318 181.31 301.763 179.756 299.846 179.756C297.929 179.756 296.374 181.31 296.374 183.227C296.374 185.145 297.929 186.699 299.846 186.699Z" fill="#FFB6B6"/>
      <path d="M297.018 180.245C297.304 179.415 298.725 178.838 301.029 178.758C303.333 178.678 303.918 181.393 303.918 181.393C305.456 182.641 302.275 185.982 302.121 185.861L302.19 185.234C302.305 184.194 302.792 183.383 302.173 182.3L301.578 182.307L300.451 182.523C300.38 182.537 300.308 182.527 300.244 182.495C300.18 182.463 300.128 182.411 300.096 182.347C300.065 182.282 300.055 182.21 300.069 182.139C300.083 182.069 300.12 182.006 300.174 181.958L300.184 181.949L300.158 181.909C299.761 182.092 299.352 182.261 298.917 182.286C298.429 182.315 297.895 182.12 297.661 181.691C297.598 181.573 297.56 181.443 297.549 181.31C296.89 181.573 296.589 182.385 296.589 182.385C296.589 182.385 296.733 181.076 297.018 180.245Z" fill="#2F2E41"/>
      <path d="M300.355 195.587V196.254H298.143V195.587H298.828V193.979L298.188 194.347V193.626L299.173 193.066H299.665V195.587L300.355 195.587Z" fill="white"/>
      <path d="M300.853 195.874C300.643 195.593 300.538 195.187 300.538 194.656C300.538 194.133 300.643 193.733 300.853 193.454C300.965 193.322 301.104 193.217 301.261 193.145C301.417 193.072 301.588 193.035 301.76 193.035C301.933 193.035 302.103 193.072 302.259 193.145C302.416 193.217 302.555 193.322 302.667 193.454C302.877 193.733 302.982 194.133 302.982 194.656C302.982 195.187 302.877 195.593 302.667 195.874C302.555 196.005 302.416 196.111 302.26 196.184C302.103 196.257 301.933 196.294 301.76 196.294C301.587 196.294 301.417 196.257 301.26 196.184C301.104 196.111 300.965 196.005 300.853 195.874ZM302.069 195.428C302.145 195.178 302.176 194.917 302.163 194.656C302.176 194.4 302.144 194.144 302.069 193.899C302.05 193.832 302.009 193.773 301.954 193.731C301.898 193.689 301.83 193.666 301.76 193.666C301.69 193.666 301.622 193.689 301.567 193.731C301.511 193.773 301.47 193.832 301.451 193.899C301.376 194.144 301.344 194.4 301.357 194.656C301.344 194.917 301.376 195.178 301.451 195.428C301.47 195.496 301.51 195.555 301.566 195.598C301.622 195.64 301.69 195.663 301.76 195.663C301.83 195.663 301.898 195.64 301.954 195.598C302.01 195.555 302.05 195.496 302.069 195.428Z" fill="white"/>
      <path d="M276.068 188.237L277.049 190.804L275.839 192.056L276.372 192.533L275.979 193.207L274.954 200.268L280.572 202.12L282.463 200.058L283.955 202.12L290.455 200.739L288.797 191.735V191.146L288.329 190.009V187.603L290.479 180.44L285.876 179.317L284.113 178.103L283.542 179.81L281.112 179.434L281.048 178.152L279.189 179.679L276.71 180.067L274.835 180.44L274.596 180.959L275.074 180.855L275.91 187.608L276.068 188.237Z" fill="#2F2E41"/>
      <path d="M279.848 181.306L281.048 178.159L284.112 178.103L285.129 181.306L288.784 201.723C288.784 201.723 277.587 204.841 277.701 201.093C277.82 197.152 278.359 191.917 278.359 191.917L279.848 181.306Z" fill="#3F3D56"/>
      <path d="M282.512 177.28C284.505 177.28 286.122 175.664 286.122 173.67C286.122 171.676 284.505 170.06 282.512 170.06C280.518 170.06 278.902 171.676 278.902 173.67C278.902 175.664 280.518 177.28 282.512 177.28Z" fill="#A0616A"/>
      <path d="M278.689 171.051C279.277 170.412 280.012 169.241 282.239 168.749C284.99 168.141 286.29 170.627 285.596 170.668C287.625 171.282 286.321 175.48 285.74 175.128C285.735 175.125 285.73 175.121 285.725 175.117C285.413 174.886 285.15 174.595 284.954 174.26C284.758 173.924 284.632 173.553 284.584 173.167L284.474 172.174C284.458 172.982 283.632 173.089 282.788 172.791C282.837 172.614 282.933 172.453 283.064 172.324C283.195 172.194 283.358 172.102 283.536 172.055C283.019 172.531 282.347 172.872 281.409 172.98C281.524 172.534 281.753 172.206 282.113 172.011L282.079 171.978C281.735 172.24 281.377 172.492 280.963 172.61C280.498 172.744 279.941 172.672 279.622 172.307C279.536 172.207 279.471 172.09 279.431 171.964C279.5 172.604 279.071 173.335 278.737 173.21C278.46 173.156 278.298 171.477 278.689 171.051Z" fill="#2F2E41"/>
      <path d="M286.963 173.471L290.246 178.994L292.672 177.848C292.672 177.848 288.491 172.626 288.358 172.476C288.415 172.222 288.393 171.957 288.296 171.715C288.2 171.474 288.032 171.267 287.815 171.123C287.598 170.98 287.343 170.905 287.083 170.909C286.823 170.914 286.57 170.997 286.358 171.148C286.146 171.299 285.985 171.511 285.897 171.755C285.808 172 285.795 172.266 285.861 172.517C285.926 172.769 286.067 172.995 286.264 173.165C286.46 173.336 286.704 173.442 286.963 173.471Z" fill="#A0616A"/>
      <path d="M288.552 187.196L293.647 183.525C293.979 183.286 294.258 182.98 294.465 182.626C294.673 182.273 294.804 181.88 294.851 181.473C294.898 181.066 294.859 180.654 294.738 180.263C294.616 179.872 294.415 179.51 294.145 179.201L290.479 174.995L288.881 177.16L290.113 179.734L287.729 180.363L288.552 187.196Z" fill="#2F2E41"/>
      <path d="M279.807 173.99L276.524 179.513L274.098 178.367C274.098 178.367 278.279 173.145 278.412 172.995C278.355 172.741 278.376 172.476 278.473 172.234C278.57 171.993 278.738 171.786 278.955 171.643C279.172 171.499 279.427 171.424 279.687 171.428C279.947 171.433 280.2 171.516 280.412 171.667C280.624 171.818 280.784 172.03 280.873 172.274C280.962 172.519 280.974 172.785 280.909 173.036C280.843 173.288 280.703 173.514 280.506 173.684C280.31 173.855 280.066 173.961 279.807 173.99Z" fill="#A0616A"/>
      <path d="M278.218 187.715L273.123 184.044C272.79 183.805 272.512 183.499 272.304 183.146C272.097 182.792 271.966 182.399 271.919 181.992C271.872 181.586 271.91 181.173 272.032 180.782C272.153 180.391 272.355 180.029 272.624 179.72L276.291 175.514L277.889 177.679L276.657 180.253L279.041 180.882L278.218 187.715Z" fill="#2F2E41"/>
      <path d="M283.373 186.688C283.488 186.824 283.548 186.997 283.543 187.176C283.547 187.352 283.49 187.524 283.382 187.664C283.265 187.808 283.111 187.917 282.936 187.979C282.514 188.125 282.055 188.125 281.633 187.979C281.459 187.917 281.304 187.808 281.188 187.664C281.08 187.524 281.023 187.352 281.027 187.176C281.021 186.995 281.081 186.819 281.197 186.681C281.323 186.54 281.491 186.444 281.676 186.405C281.514 186.355 281.369 186.26 281.259 186.13C281.158 186.007 281.104 185.853 281.107 185.694C281.104 185.527 281.159 185.364 281.262 185.232C281.372 185.096 281.518 184.991 281.682 184.93C282.072 184.79 282.498 184.79 282.887 184.93C283.052 184.991 283.197 185.096 283.308 185.232C283.411 185.364 283.465 185.527 283.462 185.694C283.465 185.856 283.41 186.014 283.308 186.139C283.201 186.268 283.059 186.363 282.898 186.41C283.081 186.452 283.246 186.549 283.373 186.688ZM282.634 187.368C282.677 187.34 282.712 187.302 282.736 187.256C282.759 187.211 282.77 187.16 282.768 187.108C282.769 187.057 282.758 187.005 282.736 186.958C282.713 186.912 282.68 186.871 282.638 186.84C282.544 186.748 282.417 186.696 282.285 186.696C282.153 186.696 282.026 186.748 281.931 186.84C281.89 186.871 281.856 186.912 281.834 186.958C281.811 187.005 281.8 187.057 281.801 187.108C281.799 187.16 281.81 187.211 281.834 187.256C281.857 187.302 281.892 187.34 281.935 187.368C282.042 187.427 282.163 187.458 282.285 187.458C282.407 187.458 282.527 187.427 282.634 187.368ZM282 185.539C281.963 185.569 281.933 185.608 281.914 185.652C281.895 185.696 281.887 185.744 281.891 185.792C281.891 185.989 282.022 186.12 282.285 186.186C282.547 186.121 282.679 185.989 282.679 185.792C282.682 185.744 282.674 185.696 282.655 185.652C282.636 185.608 282.606 185.569 282.569 185.539C282.484 185.485 282.385 185.456 282.285 185.456C282.184 185.456 282.085 185.485 282 185.539H282Z" fill="white"/>
      <path d="M243.018 176.069C243.096 176.185 243.199 176.282 243.319 176.353C243.439 176.425 243.573 176.47 243.712 176.484C243.851 176.498 243.991 176.482 244.123 176.436C244.255 176.39 244.375 176.316 244.476 176.219L247.51 177.743L247.304 175.991L244.453 174.811C244.279 174.654 244.052 174.566 243.817 174.564C243.582 174.563 243.354 174.648 243.177 174.802C243 174.957 242.886 175.172 242.857 175.405C242.828 175.639 242.885 175.875 243.018 176.069Z" fill="#9E616A"/>
      <path d="M255.573 183.144L254.062 182.102C254.062 182.102 252.331 181.985 252.071 181.435C251.811 180.884 251.767 181.069 251.49 181.004C251.212 180.938 251.069 180.11 250.604 180.207C249.558 180.427 244.637 177.176 245.168 176.769L245.563 175.105C245.98 175.26 246.415 175.364 246.857 175.412C247.527 175.473 249.104 176.238 249.337 176.488C249.571 176.739 249.985 176.642 250.539 176.773C251.094 176.905 251.288 177.798 252.209 177.56C253.504 177.226 260.212 180.408 260.938 180.705C261.734 181.031 262.291 182.002 262.291 182.002L262.071 182.927L255.573 183.144Z" fill="var(--color-primary)"/>
      <path d="M263.823 215.305L261.627 205.056L258.198 216.443L252.768 227.241L252.739 227.298L251.019 226.372C250.776 221.988 251.353 217.879 253.462 214.287L254.46 204.426C254.491 204.089 255.236 196.179 257.594 194.776L258.285 193.201L265.154 191.505L265.179 191.533C265.84 192.275 266.276 193.19 266.438 194.17L268.491 206.606L268.492 206.613L267.941 215.288L268.694 228.087H265.97C264.285 224.916 263.569 220.655 263.823 215.305Z" fill="#2F2E41"/>
      <path d="M269.737 172.324C269.842 172.416 269.925 172.531 269.981 172.659C270.037 172.787 270.064 172.926 270.06 173.065C270.056 173.205 270.022 173.342 269.959 173.467C269.897 173.592 269.808 173.702 269.698 173.789L270.82 176.995L269.109 176.564L268.306 173.586C268.172 173.392 268.114 173.156 268.143 172.923C268.171 172.69 268.285 172.475 268.461 172.319C268.637 172.164 268.865 172.078 269.1 172.079C269.335 172.08 269.562 172.167 269.737 172.324H269.737Z" fill="#9E616A"/>
      <path d="M256.26 196.538C256.075 196.538 255.97 196.502 255.929 196.424C255.869 196.31 255.973 196.16 256.093 195.988C256.177 195.867 256.421 195.628 256.301 195.613C254.479 195.391 255.353 184.155 256.143 182.67C256.304 182.368 256.134 181.905 256.315 181.393C256.808 179.995 257.482 179.565 258.824 178.709C258.933 178.639 259.047 178.566 259.165 178.491C259.317 178.393 259.309 178.068 259.3 177.723C259.29 177.341 259.279 176.945 259.467 176.698L259.479 176.682L259.499 176.677C259.952 176.572 261.477 176.378 262.38 176.678L262.393 176.683L262.402 176.692C262.616 176.906 262.636 177.322 262.655 177.724C262.67 178.054 262.685 178.365 262.806 178.5C263.734 179.535 264.787 179.515 264.797 179.514L264.85 179.513L264.855 179.565C265.505 180.803 264.996 185.402 265.448 188.258C265.907 191.16 269.831 193.631 266.471 194.069C266.479 194.08 266.483 194.093 266.483 194.107C266.483 194.12 266.48 194.133 266.474 194.145C266.271 194.524 256.957 196.538 256.26 196.538Z" fill="var(--color-primary)"/>
      <path d="M271.447 182.386L269.583 183.311C265.989 184.691 264.112 184.355 263.168 183.83C262.161 183.271 261.987 182.412 261.98 182.376L261.979 182.358L262.209 180.536C262.239 180.305 262.317 180.082 262.439 179.883C262.56 179.685 262.723 179.514 262.916 179.382C263.109 179.251 263.327 179.162 263.557 179.122C263.787 179.081 264.022 179.09 264.248 179.148L268.538 180.239L268.46 178.157L268.63 177.407L268.317 176.496L268.445 174.665L269.984 174.289L271.598 177.18L271.308 177.978L271.581 178.375L271.581 178.393L271.447 182.386Z" fill="var(--color-primary)"/>
      <path d="M262.093 183.848V184.475L260.705 187.036H259.809L261.193 184.515H259.724V183.848L262.093 183.848Z" fill="white"/>
      <path d="M263.809 177.413C263.713 177.238 263.547 176.735 263.362 176.742C263.172 176.75 262.981 176.734 262.795 176.694C262.775 176.69 262.755 176.686 262.736 176.681C261.951 176.486 261.146 176.38 260.337 176.368C259.685 176.359 258.968 176.437 258.513 176.904C258.057 177.371 258.124 178.333 258.752 178.511C256.654 178.875 254.497 178.397 252.748 177.181C251.637 176.399 250.746 175.353 249.804 174.375C248.861 173.395 247.822 172.454 246.552 171.975C245.281 171.495 243.726 171.562 242.702 172.456C241.453 173.546 241.356 175.422 241.355 177.08C241.354 177.7 241.548 177.569 242.095 177.861C242.603 178.132 243.137 178.689 243.447 178.205C243.757 177.72 243.812 177.121 243.876 176.549C243.939 175.977 244.031 175.375 244.394 174.929C245.018 174.161 246.245 174.153 247.106 174.64C247.967 175.127 248.546 175.978 249.093 176.802C249.64 177.625 250.218 178.479 251.077 178.969C252.207 179.613 253.625 179.518 254.803 180.066C255.552 180.415 256.158 181.001 256.779 181.544C257.401 182.086 258.083 182.609 258.89 182.785C259.696 182.962 260.653 182.695 261.049 181.971C261.406 181.316 261.232 180.478 261.549 179.802C261.795 179.28 262.295 178.935 262.746 178.574C262.932 178.425 262.98 178.938 263.138 178.757C263.593 178.666 264.021 177.8 263.809 177.413Z" fill="#3F3D56"/>
      <path d="M260.671 176.487C262.624 176.487 264.208 174.904 264.208 172.951C264.208 170.997 262.624 169.414 260.671 169.414C258.718 169.414 257.134 170.997 257.134 172.951C257.134 174.904 258.718 176.487 260.671 176.487Z" fill="#2F2E41"/>
      <path d="M260.956 176.78C262.359 176.74 263.464 175.57 263.424 174.167C263.385 172.764 262.215 171.659 260.812 171.698C259.408 171.738 258.303 172.908 258.343 174.311C258.383 175.714 259.552 176.819 260.956 176.78Z" fill="#9E616A"/>
      <path d="M260.908 170.363L259.878 170.392C259.228 170.411 258.613 170.687 258.167 171.159C257.721 171.631 257.48 172.261 257.498 172.91L257.498 172.933L257.517 172.944C258.077 173.315 258.738 173.503 259.409 173.483L259.467 173.481L260.037 173.455C260.132 173.209 260.164 172.942 260.13 172.68C260.216 172.928 260.283 173.183 260.33 173.442L262.885 173.326C263.034 173.318 263.173 173.253 263.275 173.144C263.376 173.035 263.431 172.891 263.427 172.742C263.408 172.093 263.132 171.477 262.66 171.031C262.187 170.585 261.557 170.345 260.908 170.363Z" fill="#2F2E41"/>
      <path d="M258.855 169.725C258.623 170.011 258.249 170.131 257.914 170.286C257.322 170.566 256.807 170.986 256.414 171.51C256.021 172.034 255.762 172.646 255.659 173.293C255.58 173.819 255.608 174.361 255.474 174.876C255.341 175.391 254.99 175.903 254.468 176.006C254.116 176.077 253.756 175.95 253.419 175.824C253.233 175.756 253.048 175.687 252.862 175.618C253.04 175.132 253.217 174.647 253.395 174.161C253.101 174.623 252.756 175.05 252.367 175.435C251.702 175.189 251.037 174.942 250.371 174.696C251.991 174.656 251.67 173.091 251.988 171.503C252.162 170.63 253.842 169.995 254.151 169.161C254.318 168.708 254.612 168.313 254.996 168.022C255.381 167.731 255.841 167.556 256.322 167.518C256.803 167.48 257.285 167.581 257.71 167.808C258.135 168.036 258.487 168.38 258.723 168.801C259.059 168.924 259.083 169.442 258.855 169.725Z" fill="#2F2E41"/>
      <path d="M259.017 170.776C259.744 170.776 260.334 170.187 260.334 169.459C260.334 168.732 259.744 168.142 259.017 168.142C258.29 168.142 257.7 168.732 257.7 169.459C257.7 170.187 258.29 170.776 259.017 170.776Z" fill="#2F2E41"/>
      </g>
      <path d="M313.714 208.857H245.538C242.685 208.854 239.949 207.719 237.931 205.701C235.913 203.683 234.778 200.947 234.775 198.093V154.161C234.778 151.308 235.913 148.572 237.931 146.554C239.949 144.536 242.685 143.401 245.538 143.398H313.714C316.568 143.401 319.304 144.536 321.322 146.554C323.34 148.572 324.475 151.308 324.478 154.161V198.093C324.475 200.947 323.34 203.683 321.322 205.701C319.304 207.719 316.568 208.854 313.714 208.857ZM245.538 144.206C242.899 144.209 240.368 145.259 238.502 147.125C236.636 148.991 235.586 151.522 235.583 154.161V198.093C235.586 200.733 236.636 203.263 238.502 205.129C240.368 206.996 242.899 208.046 245.538 208.049H313.714C316.354 208.046 318.884 206.996 320.751 205.129C322.617 203.263 323.667 200.733 323.67 198.093V154.161C323.667 151.522 322.617 148.991 320.751 147.125C318.884 145.259 316.354 144.209 313.714 144.206H245.538Z" fill="#3F3D56"/>
      <path d="M112.6 14.9043C109.853 14.9074 107.22 15.9998 105.278 17.9419C103.336 19.884 102.244 22.5171 102.241 25.2637V69.1957C102.244 71.9423 103.336 74.5754 105.278 76.5175C107.22 78.4596 109.853 79.552 112.6 79.5551H180.776C183.523 79.552 186.156 78.4596 188.098 76.5175C190.04 74.5754 191.132 71.9423 191.135 69.1957V25.2637C191.132 22.5171 190.04 19.884 188.098 17.9419C186.156 15.9998 183.523 14.9074 180.776 14.9043H112.6Z" fill="white"/>
      <mask id="mask2_13_23182" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="110" y="23" width="74" height="45">
      <path d="M183.359 24.1081V66.0676C183.359 66.2063 183.332 66.3436 183.279 66.4718C183.226 66.5999 183.148 66.7164 183.05 66.8144C182.952 66.9125 182.835 66.9904 182.707 67.0435C182.579 67.0966 182.442 67.124 182.303 67.124H111.074C111.023 67.1244 110.972 67.1205 110.922 67.1122C110.671 67.0761 110.441 66.9505 110.275 66.7587C110.109 66.5668 110.017 66.3215 110.017 66.0676V24.1081C110.017 23.9695 110.044 23.8321 110.097 23.704C110.15 23.5758 110.228 23.4594 110.326 23.3613C110.424 23.2632 110.541 23.1854 110.669 23.1323C110.797 23.0792 110.934 23.0518 111.073 23.0518H182.302C182.441 23.0517 182.578 23.079 182.707 23.132C182.835 23.185 182.951 23.2628 183.049 23.3608C183.147 23.4588 183.225 23.5752 183.278 23.7033C183.331 23.8314 183.359 23.9695 183.359 24.1081Z" fill="#E6E6E6"/>
      </mask>
      <g mask="url(#mask2_13_23182)">
      <path d="M183.359 24.1081V66.0676C183.359 66.2063 183.332 66.3436 183.279 66.4718C183.226 66.5999 183.148 66.7164 183.05 66.8144C182.952 66.9125 182.835 66.9904 182.707 67.0435C182.579 67.0966 182.442 67.124 182.303 67.124H111.074C111.023 67.1244 110.972 67.1205 110.922 67.1122C110.671 67.0761 110.441 66.9505 110.275 66.7587C110.109 66.5668 110.017 66.3215 110.017 66.0676V24.1081C110.017 23.9695 110.044 23.8321 110.097 23.704C110.15 23.5758 110.228 23.4594 110.326 23.3613C110.424 23.2632 110.541 23.1854 110.669 23.1323C110.797 23.0792 110.934 23.0518 111.073 23.0518H182.302C182.441 23.0517 182.578 23.079 182.707 23.132C182.835 23.185 182.951 23.2628 183.049 23.3608C183.147 23.4588 183.225 23.5752 183.278 23.7033C183.331 23.8314 183.359 23.9695 183.359 24.1081Z" fill="#E6E6E6"/>
      <g opacity="0.25">
      <path d="M127.972 48.0038C133.091 48.0038 137.24 43.8544 137.24 38.7359C137.24 33.6175 133.091 29.4681 127.972 29.4681C122.854 29.4681 118.704 33.6175 118.704 38.7359C118.704 43.8544 122.854 48.0038 127.972 48.0038Z" fill="var(--color-primary)"/>
      <path d="M157.035 67.284H111.57C111.513 67.2848 111.456 67.2803 111.4 67.2706L132.903 30.0245C133.034 29.7925 133.225 29.5995 133.455 29.4653C133.685 29.331 133.947 29.2603 134.213 29.2603C134.48 29.2603 134.742 29.331 134.972 29.4653C135.202 29.5995 135.392 29.7925 135.524 30.0245L149.955 55.0195L150.646 56.2155L157.035 67.284Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M157.035 67.29H141.512L149.132 56.2213L149.681 55.4242L149.955 55.0255L150.646 56.2213L157.035 67.29Z" fill="black"/>
      <path d="M182.35 67.2741H143.026L150.646 56.2055L151.195 55.4082L161.124 40.9838C161.775 40.0384 163.341 39.9795 164.107 40.8055C164.158 40.8618 164.206 40.9214 164.25 40.9838L182.35 67.2741Z" fill="#3F3D56"/>
      </g>
      <path d="M138.013 58.7578C138.013 58.7578 136.791 49.7915 134.753 52.2369C132.715 54.6822 134.753 58.3502 134.753 58.3502L136.383 60.388L138.013 59.1654V58.7578Z" fill="#F4C2AD"/>
      <path d="M142.904 61.6117C142.904 61.6117 138.828 57.1285 138.013 57.9437C137.198 58.7588 134.753 59.1663 135.16 60.7966C135.568 62.4268 144.126 67.725 144.126 67.725L142.904 61.6117Z" fill="var(--color-primary)"/>
      <path opacity="0.1" d="M142.904 61.6117C142.904 61.6117 138.828 57.1285 138.013 57.9437C137.198 58.7588 134.753 59.1663 135.16 60.7966C135.568 62.4268 144.126 67.725 144.126 67.725L142.904 61.6117Z" fill="black"/>
      <path d="M166.135 61.2039V64.4643C166.135 64.4643 166.542 71.8004 164.097 71.3928C161.652 70.9852 162.874 64.8719 162.874 64.8719L163.282 61.2039L166.135 61.2039Z" fill="#F4C2AD"/>
      <path d="M155.946 66.5C155.946 66.5 161.652 67.3151 161.244 75.4663C161.244 75.4663 168.58 82.8023 166.542 85.2476C164.505 87.693 160.021 92.1761 158.391 90.9534C156.761 89.7308 151.87 80.3569 151.87 80.3569C151.87 80.3569 140.459 84.4325 138.421 82.3947C136.383 80.357 134.345 75.4663 134.345 75.4663L144.534 70.168L155.946 66.5Z" fill="#2F2E41"/>
      <path d="M140.051 43.2724C142.752 43.2724 144.942 41.0827 144.942 38.3817C144.942 35.6806 142.752 33.491 140.051 33.491C137.35 33.491 135.16 35.6806 135.16 38.3817C135.16 41.0827 137.35 43.2724 140.051 43.2724Z" fill="#F4C2AD"/>
      <path d="M143.719 38.7891C143.719 38.7891 146.572 43.2722 147.387 43.6798C148.202 44.0873 141.681 48.1629 141.681 48.1629C141.681 48.1629 141.681 42.8646 139.643 41.642L143.719 38.7891Z" fill="#F4C2AD"/>
      <path d="M144.126 43.2707C144.126 43.2707 150.647 41.2329 151.87 44.4933C153.093 47.7538 155.945 53.8672 155.538 57.9427C155.13 62.0183 154.315 61.2032 155.538 62.4259C156.761 63.6485 157.576 62.8334 157.168 64.4637C156.761 66.0939 156.353 66.0939 156.761 66.909C157.168 67.7241 151.87 70.577 147.387 70.9846L142.904 71.3921C142.904 71.3921 142.904 64.0561 142.089 62.4259C141.273 60.7956 139.643 57.5352 139.643 56.3125V53.0774C139.643 50.9242 140.205 48.8082 141.273 46.9387C141.273 46.9387 143.719 43.2707 144.126 43.2707Z" fill="var(--color-primary)"/>
      <path d="M149.017 44.9019C149.017 44.9019 164.097 46.1245 165.727 50.2001C167.357 54.2757 168.172 62.4268 166.542 62.4268C164.912 62.4268 162.874 63.2419 162.467 62.4268C162.059 61.6117 160.429 53.4606 160.021 53.4606C159.614 53.4606 147.387 52.2379 147.387 52.2379C147.387 52.2379 142.089 45.717 149.017 44.9019Z" fill="var(--color-primary)"/>
      <path d="M139.855 37.1684C139.855 37.1684 138.557 34.913 136.934 36.8721C135.312 38.8311 132.673 35.231 133.078 34.4628C133.483 33.6947 134.914 34.4974 137.366 33.6959C139.818 32.8943 144.541 31.2615 145.332 36.5879C146.123 41.9143 144.239 41.3559 144.239 41.3559C144.239 41.3559 143.375 36.6361 142.595 37.9919L141.814 39.3476L141.137 39.5142L139.855 37.1684Z" fill="#2F2E41"/>
      </g>
      <path d="M180.776 79.9592H112.6C109.746 79.956 107.01 78.8209 104.993 76.8031C102.975 74.7852 101.84 72.0494 101.836 69.1958V25.2637C101.84 22.41 102.975 19.6742 104.993 17.6564C107.01 15.6385 109.746 14.5035 112.6 14.5002H180.776C183.63 14.5035 186.366 15.6385 188.383 17.6564C190.401 19.6742 191.536 22.41 191.539 25.2637V69.1958C191.536 72.0494 190.401 74.7852 188.383 76.8031C186.366 78.8209 183.63 79.956 180.776 79.9592ZM112.6 15.3084C109.961 15.3113 107.43 16.3611 105.564 18.2275C103.697 20.0938 102.648 22.6243 102.645 25.2637V69.1958C102.648 71.8352 103.697 74.3656 105.564 76.2319C107.43 78.0983 109.961 79.1481 112.6 79.1511H180.776C183.415 79.1481 185.946 78.0983 187.812 76.2319C189.679 74.3656 190.728 71.8352 190.731 69.1958V25.2637C190.728 22.6243 189.679 20.0938 187.812 18.2275C185.946 16.3611 183.415 15.3113 180.776 15.3084H112.6Z" fill="#3F3D56"/>
      <path d="M168.126 27.636C166.044 18.8896 166.391 10.2749 169.957 1.83474C169.995 1.31256 170.238 0.826648 170.633 0.483587C171.029 0.140526 171.544 -0.0316593 172.067 0.00480496L191.552 1.38744C192.074 1.42508 192.56 1.66832 192.903 2.06378C193.246 2.45925 193.419 2.97464 193.382 3.4969L191.551 29.2981C191.514 29.8203 191.271 30.3062 190.875 30.6493C190.48 30.9923 189.964 31.1645 189.442 31.1281L169.956 29.7454C169.434 29.7078 168.948 29.4645 168.605 29.0691C168.262 28.6736 168.09 28.1582 168.126 27.636Z" fill="#F2F2F2"/>
      <path d="M170.174 19.9487C168.507 15.6387 169.573 9.71095 171.351 3.36136C171.386 2.87232 171.614 2.41724 171.984 2.09595C172.355 1.77467 172.837 1.61341 173.327 1.64757L190.073 2.83583C190.562 2.87107 191.017 3.09886 191.338 3.46923C191.66 3.83959 191.821 4.32229 191.787 4.81141L190.158 27.771C190.122 28.26 189.895 28.7151 189.524 29.0364C189.154 29.3577 188.671 29.5189 188.182 29.4848L177.808 28.7487C175.63 28.5916 173.603 27.577 172.171 25.9272C170.74 24.2775 170.022 22.1274 170.174 19.9487Z" fill="white"/>
      <path d="M187.847 9.23614C183.912 11.1737 180.803 12.9688 179.168 17.0203C178.115 14.5937 177.063 13.7833 176.01 11.3567C175.7 10.6429 174.655 11.2594 174.963 11.9685C176.209 14.8409 177.456 17.7133 178.702 20.5857C178.922 21.0924 179.672 20.8943 179.81 20.441C180.484 18.2483 181.599 16.2164 183.086 14.4697C184.573 12.723 186.401 11.2983 188.459 10.2829C189.158 9.93825 188.545 8.89239 187.847 9.23614Z" fill="var(--color-primary)"/>
      <path d="M36.8044 175.929C34.7219 167.182 35.0692 158.568 38.6352 150.128C38.6728 149.605 38.916 149.119 39.3115 148.776C39.707 148.433 40.2224 148.261 40.7446 148.298L60.2303 149.68C60.7525 149.718 61.2384 149.961 61.5814 150.357C61.9245 150.752 62.0967 151.267 62.0602 151.79L60.2294 177.591C60.1918 178.113 59.9486 178.599 59.5531 178.942C59.1576 179.285 58.6422 179.457 58.12 179.421L38.6343 178.038C38.1122 178.001 37.6262 177.757 37.2832 177.362C36.9401 176.966 36.7679 176.451 36.8044 175.929Z" fill="#F2F2F2"/>
      <path d="M38.852 168.242C37.1854 163.932 38.251 158.004 40.029 151.654C40.0643 151.165 40.2921 150.71 40.6624 150.389C41.0328 150.068 41.5155 149.906 42.0046 149.94L58.7509 151.129C59.2399 151.164 59.695 151.392 60.0163 151.762C60.3376 152.132 60.4989 152.615 60.4647 153.104L58.8356 176.064C58.8004 176.553 58.5725 177.008 58.2022 177.329C57.8318 177.651 57.3491 177.812 56.86 177.778L46.486 177.042C44.3076 176.885 42.2805 175.87 40.8494 174.22C39.4182 172.57 38.6999 170.42 38.852 168.242Z" fill="white"/>
      <path d="M56.4716 157.634C52.5367 159.571 49.4277 161.367 47.7933 165.418C46.7404 162.991 45.6875 162.181 44.6346 159.754C44.3248 159.041 43.2802 159.657 43.5879 160.366C44.8342 163.239 46.0805 166.111 47.3268 168.983C47.5467 169.49 48.2973 169.292 48.4346 168.839C49.109 166.646 50.224 164.614 51.7111 162.867C53.1983 161.121 55.0264 159.696 57.0835 158.681C57.7833 158.336 57.1697 157.29 56.4716 157.634Z" fill="var(--color-primary)"/>
      <path d="M241.263 172.292C239.18 163.546 239.527 154.931 243.093 146.491C243.131 145.969 243.374 145.483 243.77 145.14C244.165 144.797 244.681 144.625 245.203 144.661L264.688 146.044C265.211 146.081 265.697 146.325 266.04 146.72C266.383 147.116 266.555 147.631 266.518 148.153L264.688 173.954C264.65 174.477 264.407 174.962 264.011 175.306C263.616 175.649 263.1 175.821 262.578 175.784L243.093 174.402C242.57 174.364 242.084 174.121 241.741 173.725C241.398 173.33 241.226 172.814 241.263 172.292Z" fill="#F2F2F2"/>
      <path d="M243.31 164.605C241.644 160.295 242.709 154.367 244.487 148.018C244.522 147.529 244.75 147.073 245.121 146.752C245.491 146.431 245.974 146.27 246.463 146.304L263.209 147.492C263.698 147.527 264.153 147.755 264.475 148.125C264.796 148.496 264.957 148.979 264.923 149.468L263.294 172.427C263.259 172.916 263.031 173.371 262.66 173.693C262.29 174.014 261.807 174.175 261.318 174.141L250.944 173.405C248.766 173.248 246.739 172.233 245.308 170.583C243.876 168.934 243.158 166.784 243.31 164.605Z" fill="white"/>
      <path d="M260.93 153.997C256.995 155.935 253.886 157.73 252.251 161.781C251.199 159.355 250.146 158.544 249.093 156.118C248.783 155.404 247.738 156.021 248.046 156.73C249.292 159.602 250.539 162.474 251.785 165.347C252.005 165.854 252.756 165.655 252.893 165.202C253.567 163.009 254.682 160.977 256.169 159.231C257.657 157.484 259.485 156.059 261.542 155.044C262.241 154.699 261.628 153.654 260.93 153.997Z" fill="var(--color-primary)"/>
      <path d="M247.817 142.208L253.162 133.474L258.578 135.831L252.598 144.865C252.687 145.496 252.598 146.14 252.342 146.724C252.085 147.307 251.67 147.808 251.145 148.169C250.619 148.53 250.003 148.737 249.366 148.767C248.729 148.798 248.096 148.65 247.539 148.34C246.982 148.03 246.522 147.571 246.211 147.014C245.9 146.457 245.75 145.825 245.779 145.188C245.808 144.551 246.014 143.935 246.374 143.408C246.734 142.882 247.234 142.466 247.817 142.208Z" fill="#FFB6B6"/>
      <path d="M248.336 81.0825C248.336 81.0825 255.801 78.3665 256.741 80.2475C257.682 82.1285 262.732 116.453 262.088 118.257C261.444 120.062 257.368 115.046 261.444 120.062C265.519 125.078 264.892 123.824 263.952 124.764C263.011 125.705 259.132 138.874 259.132 138.874L248.336 137.452L252.039 116.927L247.27 91.288L248.336 81.0825Z" fill="var(--color-primary)"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_13_23182">
      <rect width="360" height="240" fill="white"/>
      </clipPath>
      <clipPath id="clip1_13_23182">
      <rect width="328" height="244.472" fill="white" transform="translate(16)"/>
      </clipPath>
      </defs>
    </svg>
  </div>
</template>


<script lang="js">
import Common from "@/plugins/common";
import { RATES } from '@/assets/data/currencyRates';

export default {
  name: 'PackageOffersBanner',
  props: {
    onePhotoPrice: Number,
    allPhotosPrice: Number,
    allPhotosDiscount: Number,
    isVertical: Boolean,
    isBelarussian: Boolean,
  },
  computed: {
    subtitle() {
      if (this.isBelarussian) {
        return this.$t('ssb/bannerPackagesDescription', [
          `${this.onePhotoPrice}&nbsp;₽ / ${this.calcPriceInByn(this.onePhotoPrice)}&nbsp;BYN`,
          `${this.allPhotosPrice}&nbsp;₽ / ${this.calcPriceInByn(this.allPhotosPrice)}&nbsp;BYN`,
        ]);
      }
      return this.$t('packageOffers/bannerText');
    },
  },
  methods: {
    calcPriceInByn(rub) {
      return Math.round(rub / RATES.BYN);
    },
    formatAmount(amount, onlyInteger = false) {
      return Common.formatAmount(amount, onlyInteger);
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./package-offers-banner";
</style>
