<template>
  <div class="photo-frame">

    <div class="photo-frame__close">
      <Button
        variant="clear"
        shape="square"
        size="lg"
        iconLeft="close"
        mobile
        @click="$emit('closed')"
      />
    </div>

    <div class="photo-frame__preview">
      <div v-if="!$root.isMobile && !$root.isTablet" class="content__select-photo">
        <div class="member__title" @click.stop="onBack">
          <div class="member__title-button">
            <Button 
              @click.stop="onBack"
              variant="clear"
              size="sm"
              class="member__continue"
            >
              <slot name="before">
                <span class="rr-button__icon">
                  <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 24 24">
                    <icon-left2 fill="var(--color-black-45)" opacity="1" />
                  </base-icon>
                  <base-icon v-if="theme !== ''" width="20" height="20">
                    <icon-left fill="var(--color-black-45)" opacity="1" />
                  </base-icon>
                </span>
              </slot>
            </Button>
          </div>

          <div>
            <div v-if="!faceImage" class="member__number" :class="$root.isMobile ? '' : 'number--large'">
              {{ runnerNum !== '0' ? $t('cart/competitorWithN') + runnerNum : $t('cart/competitor') }} 
            </div>
            <div v-if="faceImage" class="member__number d-flex justify-start" :class="$root.isMobile ? '' : 'number--large'">
              <div>{{ $t('cart/competitor') }} </div>
              <div
                class="member__face" 
                :class="$root.isMobile ? '' : 'face--large'"
                :style="{'background-image': 'url(' + faceImage + ')'}"
              ></div>
            </div>
            <div 
              class="member__event" 
              :class="$root.isMobile || $root.isTablet ? '' : 'event--large'"
            > 
              {{ eventName }} 
            </div>
          </div>
        </div>

        <div class="select-photo__block" v-show="selected != null && !buttonDisabled && !updating">
          <img 
            :src="selectedFrameImage ? selectedFrameImage : ''"
            class="select-photo__block-image"
          />
        </div>
        
        <div class="select-photo__loader" v-show="updating">
          <Loader size="18" fill="var(--color-primary)" />
        </div>

        <div class="select-photo__slider" :class="{ 'select-photo__slider--hidden': adding || updating }">
          <div class="swiper-slider">
            <div class="swiper-navigation">
              <Button
                class="swiper-button-prev"
                variant="clear"
                shape="circle"
                size="lg"
                iconLeft="left"
              ></Button>
              <Button
                class="swiper-button-next"
                variant="clear"
                shape="circle"
                size="lg"
                iconLeft="right"
              ></Button>
            </div>
            
            <div class="swiper-wrapper" ref="wrapper">
              <div
                v-for="(item, index) in photos"
                :key="index"
                class="swiper-slide slider-item"
              >
                <div
                  class="slider-item__image"
                  :class="{ 'slider-item__image--active': selected ? selected.photoId === item.photoId : false }"
                  :style="{ 'background-image': `url(${item.resources ? item.resources.preview || '' : ''})` }"
                  @click.stop="onPhotoSelected(item)"
                ></div>
                <div class="item--in-cart" v-if="isItemInCart(item)">
                  <Icon name="check-bold" size="xxs" />
                </div>
              </div>
            </div>
          </div>
        </div>        

        <div class="content__error d-flex" v-if="buttonDisabled">
          <base-icon width="16" height="16" viewBox="0 0 16 16" class="error__icon">
            <icon-exclamation />
          </base-icon>
          <div class="error__text">
            {{ $t('photoFrame/errorText') }}
          </div>
        </div>

      </div>      
    </div>

    <div class="photo-frame__container">
      <div class="photo-frame__content" :class="$root.isMobile ? '' : 'content--large'">
        
        <div class="content__header" :class="$root.isMobile ? '' : 'header--large'" v-html="$t('searchResults/productPhotoFrame')"></div>
        <div class="content__text" :class="$root.isMobile ? '' : 'text--large'">
          {{ $t('photoFrame/contentText') }}
        </div>
        <div class="content__description">
          <div class="description__title">
            {{ $t('photoFrame/descriptionTitle') }}
          </div>
          <div class="description__text">
            <div class="text__item">
              {{ $t('photoFrame/descriptionText') }}
            </div>
          </div>
        </div>

        <div v-if="$root.isMobile || $root.isTablet" class="content__select-photo">
          <div class="member__title" @click.stop="onBack">
            <div class="member__title-button">
              <Button 
                @click.stop="onBack"
                variant="clear"
                size="sm"
                class="member__continue"
              >
                <slot name="before">
                  <span class="rr-button__icon">
                    <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 24 24">
                      <icon-left2 fill="var(--color-black-45)" opacity="1" />
                    </base-icon>
                    <base-icon v-if="theme !== ''" width="20" height="20">
                      <icon-left fill="var(--color-black-45)" opacity="1" />
                    </base-icon>
                  </span>
                </slot>
              </Button>
            </div>

            <div>
              <div v-if="!faceImage" class="member__number" :class="$root.isMobile ? '' : 'number--large'">
                {{ runnerNum !== '0' ? $t('cart/competitorWithN') + runnerNum : $t('cart/competitor') }} 
              </div>
              <div v-if="faceImage" class="member__number d-flex justify-start" :class="$root.isMobile ? '' : 'number--large'">
                <div>{{ $t('cart/competitor') }} </div>
                <div
                  class="member__face" 
                  :class="$root.isMobile ? '' : 'face--large'"
                  :style="{'background-image': 'url(' + faceImage + ')'}"
                ></div>
              </div>
              <div 
                class="member__event" 
                :class="$root.isMobile || $root.isTablet ? '' : 'event--large'"
              > 
                {{ eventName }} 
              </div>
            </div>
          </div>

          <div class="select-photo__block" v-show="selected != null && !buttonDisabled && !updating">
            <img 
              :src="selectedFrameImage ? selectedFrameImage : ''"
              class="select-photo__block-image"
            />
          </div>
          
          <div class="select-photo__loader" v-show="updating">
            <Loader size="18" fill="var(--color-primary)" />
          </div>

          <div class="select-photo__slider" :class="{ 'select-photo__slider--hidden': adding || updating }">
            <div class="swiper-slider">
              <div class="swiper-navigation">
                <Button
                  class="swiper-button-prev"
                  variant="clear"
                  shape="circle"
                  size="lg"
                  iconLeft="left"
                ></Button>
                <Button
                  class="swiper-button-next"
                  variant="clear"
                  shape="circle"
                  size="lg"
                  iconLeft="right"
                ></Button>
              </div>
              
              <div class="swiper-wrapper" ref="wrapper">
                <div
                  v-for="(item, index) in photos"
                  :key="index"
                  class="swiper-slide slider-item"
                >
                  <div
                    class="slider-item__image"
                    :class="{ 'slider-item__image--active': selected ? selected.photoId === item.photoId : false }"
                    :style="{ 'background-image': `url(${item.resources ? item.resources.preview || '' : ''})` }"
                    @click.stop="onPhotoSelected(item)"
                  ></div>
                  <div class="item--in-cart" v-if="isItemInCart(item)">
                    <Icon name="check-bold" size="xxs" />
                  </div>
                </div>
              </div>
            </div>
          </div>          

          <div class="content__error d-flex" v-if="buttonDisabled">
            <base-icon width="16" height="16" viewBox="0 0 16 16" class="error__icon">
              <icon-exclamation />
            </base-icon>
            <div class="error__text">
              {{ $t('photoFrame/errorText') }}
            </div>
          </div>

        </div>

        <div v-if="frames.length > 1 || showPhotosCountOptions || showParticipantSearch" class="content__photo-options">
          <div v-if="showPhotosCountOptions && !updating" class="content__field">
            <div class="content__type-header"><span></span>{{ $t('photoFrame/qtyHeader') }}</div>
            <div class="content__type-options">
              <div
                class="type-options__item"
                :class="{
                  'type-options__item--selected': !isAllPhotosMode,
                  'type-options__item--disabled': hasOnlyPhotosWithFrame(),
                }"
                @click.stop="hasOnlyPhotosWithFrame() ? null : onChangeOption(false)"
              >
                <div class="type-options__container">
                  <span class="type-options__title" v-html="$t('photoFrame/onePhoto')"></span>
                  <span class="type-options__price">{{ hasOnlyPhotosWithFrame() ? '' : getSelectedFrameAmount() }}</span>
                </div>
                <span class="type-options__check" :class="{ 'type-options__check--selected': !isAllPhotosMode }">
                  <Icon v-if="!isAllPhotosMode" name="check-circle-s" size="xl" />
                </span>
              </div>
              <div
                class="type-options__item"
                :class="{ 'type-options__item--selected': isAllPhotosMode }"
                @click.stop="onChangeOption(true)"
              >
                <div class="type-options__container">
                  <span class="type-options__title" v-html="$t('photoFrame/allPhotos')"></span>
                  <span class="type-options__price">{{ formatAmount(photosWithFrame.amount, true) }}</span>
                </div>
                <span class="type-options__check" :class="{ 'type-options__check--selected': isAllPhotosMode }">
                  <Icon v-if="isAllPhotosMode" name="check-circle-s" size="xl" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="frames.length > 1" class="content__field">
            <div class="content__type-header"><span v-if="showParticipantSearch">1.</span>&nbsp;{{ $t('photoFrame/typeHeader') }}</div>
            <div class="content__type-select">
              <Select
                v-model="selectedProductId"
                :options="frames"
                label="title"
                trackBy="productId"
                size="md"
                :mobile="$root.isMobile"
              >
              </Select>
            </div>
          </div>
          <div v-if="showParticipantSearch" class="content__field">
            <div class="content__type-header"><span v-html="frames.length > 1 ? '2.' : ''"></span>&nbsp;{{ $t('photoFrame/participantHeader') }}</div>
            <div class="content__type-select">
              <Select
                v-model="selectedCompetitorId"
                :options="competitors"
                label="label"
                trackBy="competitorId"
                size="md"
                :mobile="$root.isMobile"
              >
              </Select>
            </div>
          </div>
        </div>

        <div v-if="gift" class="content__gift">
          <Alert
            :text="giftText"
            state="warning"
            icon="gift"
            class="gift__alert"
            noAction
          ></Alert>
        </div>
        
        <div class="content__footer">
          <div class="footer__cart">
            <Button 
              size="lg"
              class="cart__button"
              :wide="isItemInCart(selected) ? false : true"
              :variant="isItemInCart(selected) ? 'ghost' : 'primary'"
              :view="isItemInCart(selected) ? 'success' : ''"
              :mobile="$root.isMobile"
              :text="isItemInCart(selected) ? $t('resultsItem/inCart') : getFormattedAmount()"
              :iconLeft="isItemInCart(selected) ? 'check-bold' : 'shopping-cart'"
              :disabled="buttonDisabled || updating || adding"
              :loading="updating || adding"
              @click="onAddCart"
            ></Button>
            <Button 
              v-if="isItemInCart(selected)"
              size="lg"
              class="cart__button"
              variant="secondary"
              shape="square"
              iconLeft="delete"
              :mobile="$root.isMobile"
              :disabled="buttonDisabled || updating || adding"
              :loading="updating || adding"
              @click="onRemoveCart"
            ></Button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Swiper, { Navigation } from 'swiper';
import Select from '@rr-component-library/select/src/main';
import Loader from '@/components/loader.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconLeft from '@/components/icons/icon-left.vue';
import IconLeft2 from '@/components/icons/icon-left2.vue';
import IconExclamation from '@/components/icons/icon-exclamation.vue';
import { ProductTypes } from '@/product-types';
import Common from '@/plugins/common';

Swiper.use([Navigation]);

export default {
  name: 'PhotoFrame',
  props: {
    eventId: String,
    eventName: String,
    runnerNum: String,
    runnerFrameValues: Object,
    byFace: Boolean,
    faceImage: String,
    personId: String,
    selfieId: String,
    photos: Array,
    frames: Array,
    selectedFrameProductId: String,
    selectedPhoto: Object,
    autoShowPhotoId: String,
    adding: Boolean,
    photosWithFrame: Object,
    allPhotosMode: Boolean,
    alreadyInCart: Array,
    gift: Object,
    token: String,
    searching: Object,
  },
  components: {
    Select,
    Loader,
    BaseIcon,
    IconLeft,
    IconLeft2,
    IconExclamation,
  },  
  data() {
    return {
      amount: 0,
      buttonDisabled: false,
      updating: false,
      selected: null,
      selectedFrameId: '',
      selectedProductId: '',
      selectedFrameTitle: '',
      selectedFrameImage: '',
      selectedFrameValues: null,
      showParticipantSearch: false,
      competitors: [],
      selectedCompetitorId: '',
      isAllPhotosMode: false,
      swiper: null,
      productTypes: ProductTypes,
      newToken: '',
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
    searchToken() {
      return this.$store.getters['SearchState/searchToken'];
    },
    image() {
      return this.$store.getters['FramesState/image'];
    },
    isTokenExpired() {
      return this.$store.getters['FramesState/isTokenExpired'];
    },
    showPhotosCountOptions() {
      return this.$props.photosWithFrame && !this.$props.gift ? true : false;
    },
    giftProductId() {
      return this.$props.gift ? this.$props.gift.product.productId : '';
    },
    giftText() {
      const title = this.$props.gift.product.productType === this.productTypes.frame ? this.$t('searchResults/productPhotoFrame').toString() : this.$props.gift.product.title;
      return this.$props.gift && this.giftCount ? this.$t('photoFrame/asGift', [this.giftCount, title]).toString() : '';
    },
    giftCount() {
      return this.$props.gift ? this.$props.gift.size : 0;
    },
  },
  watch: {
    selectedFrameProductId() {
      this.onSelectedFrame();
    },
    selectedCompetitorId() {
      if (!this.selectedCompetitorId) return;
    },
  },
  methods: {
    createSwiper() {
      if (this.photos.length === 0) return;

      setTimeout(() => { 
        this.swiper = new Swiper('.swiper-slider', {
          touchMoveStopPropagation: true,
          noSwipingSelector: '.swiper-button-prev, .swiper-button-next',
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }, 100);
    },
    getFormattedAmount() {
      if (this.$props.photosWithFrame && this.isAllPhotosMode) {
        this.amount = Math.round(this.$props.photosWithFrame.amount);
        return this.formatAmount(this.amount, true);
      }

      if (this.$props.frames.length == 0) return '';

      const selected = this.$props.frames.filter((r) => r.productId == this.selectedProductId);
      if (selected.length == 0) return '';

      let amount = Math.round(selected[0].amount);
      if (this.getGiftsInCartCount() < this.giftCount) {
        amount = 0;
      }
      this.amount = amount;
      return this.formatAmount(this.amount, true);
    },
    formatAmount(amount, isInteger) {
      return Common.formatAmount(amount, isInteger);
    },
    getFrameValue(item) {
      return item.productId;
    },
    getFrameText(item) {
      return item.title;
    },
    getSelectedFrameAmount() {
      if (this.$props.frames.length === 0) return '';

      const selected = this.$props.frames.filter((r) => r.productId === this.selectedProductId);
      if (selected.length === 0) return '';

      return this.formatAmount(Math.round(selected[0].amount), true);
    },
    getSelectedFrameId() {
      if (this.isAllPhotosMode) {
        return this.$props.photosWithFrame ? Common.getAttribute(this.$props.photosWithFrame.attributes, 'FrameId') : '';
      }
      
      if (this.$props.frames.length === 0) return '';

      const selected = this.$props.frames.filter((r) => r.productId === this.selectedProductId);
      if (selected.length === 0) return '';

      return Common.getAttribute(selected[0].attributes, 'FrameId');
    },
    getPayloadForFrame(token) {
      const payload = {
        params: {
          photo: this.selected.resources.watermark,
          frameId: this.selectedFrameId,
        },
      };
      if (token) payload['token'] = token;
      return payload;
    },
    setSelectedFrameValues() {
      this.selectedFrameValues = { };

      const frameValues = { ...this.$props.runnerFrameValues };
      
      if (frameValues) {
        this.selectedFrameValues['competitorId'] = frameValues.competitorId || '';
        this.selectedFrameValues['surname'] = frameValues.surname || '';
        this.selectedFrameValues['firstName'] = frameValues.firstName || '';
        this.selectedFrameValues['time'] = frameValues.time || '';
        this.selectedFrameValues['distance'] = frameValues.distance || '';
      }
    },
    setCompetitorsValues() {
      if (!this.selectedFrameValues) return;
      let name = '';
      if (this.selectedFrameValues.surname) {
        name = this.selectedFrameValues.surname + 
          (this.selectedFrameValues.firstName ? ' ' + this.selectedFrameValues.firstName : '');
      }
      const competitorId = this.selectedFrameValues.competitorId || '1';
      this.competitors = [
        { competitorId, label: `№${this.$props.runnerNum} · ${name}` },
      ];
      this.selectedCompetitorId = competitorId;
    },
    isItemInCart(item) {
      if (!item) return false;
      const photoId = item.photoId || '';
      if (!this.isAllPhotosMode) {
        const frames = this.$props.alreadyInCart.filter((i) => i.product && i.product.productType === this.productTypes.frame);
        for (let i = 0; i < frames.length; i += 1) {
          const id = frames[i].product.photo ? frames[i].product.photo.photoId : '';
          if (id && id === photoId) return frames[i];
        }
        return false;
      }
      if (this.$props.photosWithFrame) {
        const photosWithFrame = this.$props.alreadyInCart.filter((i) => i.product && i.product.productType === this.productTypes.photosWithFrame);
        for (let i = 0; i < photosWithFrame.length; i += 1) {
          const id = photosWithFrame[i].product.productId || '';
          if (id && id === this.$props.photosWithFrame.productId) return true;
        }
      }
      return false;
    },
    getGiftsInCartCount() {
      if (!this.isAllPhotosMode) {
        const inCart = this.$props.alreadyInCart.filter((i) => i.product.productId === this.giftProductId && i.product.productId === this.selectedProductId);
        return inCart.length;
      }
      return 0;
    },
    hasOnlyPhotosWithFrame() {
      if (this.$props.frames.length === 1 && this.$props.photosWithFrame) {
        return this.$props.frames[0].productId === this.$props.photosWithFrame.productId;
      }
      return false;
    },
    getPayloadForNewToken() {
      if (this.searching && this.searching.search) {
        if (this.searching.search.startNumber !== undefined) {
          return {
            startNumber: this.searching.search.startNumber,
            birthDate: this.searching.search.birthDate,
          }
        }
        if (this.searching.search.personId !== undefined) {
          return {
            personId: this.searching.search.personId,
            birthDate: this.searching.search.birthDate,
          }
        }
      }
      return null;
    },
    async getNewSearchToken(payload) {
      const tokenResult = await this.getToken(payload);
      if (tokenResult.status !== 200) return null;
      return this.searchToken;
    },
    async getToken(payload) {
      return await this.$store.dispatch('SearchState/getToken', payload);
    },
    async previewFrame(payload) {
      return await this.$store.dispatch('FramesState/previewFrame', payload);
    },
    onBack() {
      this.$emit('closed');
    },
    onSelectPhoto() {
      if (this.$props.photos.length === 0) return;
      this.buttonDisabled = false;
    },
    async onSelectedFrame() {
      if (!this.selected) return;

      this.selectedProductId = this.$props.selectedFrameProductId || this.$props.frames[0].productId;
      this.selectedFrameId = this.getSelectedFrameId();
      const token = this.newToken || this.$props.token;
      const payload = this.getPayloadForFrame(token);

      this.updating = true;
      await this.previewFrame(payload);
      if (this.isTokenExpired && this.searching) {
        const payloadForToken = this.getPayloadForNewToken();
        if (!payloadForToken) return;
        const result = await this.getNewSearchToken({
          eventId: this.eventId,
          payload: payloadForToken,
        });
        if (result) {
          payload.token = result.value;
          this.newToken = payload.token;
          await this.previewFrame(payload);
          this.updating = false;
          if (this.image !== null) {
            this.selectedFrameImage = URL.createObjectURL(this.image);
          }
          if (!this.swiper) this.createSwiper();
          return;
        }
      }
      this.updating = false;
      if (this.image !== null) {
        this.selectedFrameImage = URL.createObjectURL(this.image);
      }
      if (!this.swiper) this.createSwiper();
    },
    onPhotoSelected(photo) {
      this.selected = photo;
      this.onSelectedFrame();
    },
    onChangeOption(value) {
      if (this.updating || this.adding) return;
      this.isAllPhotosMode = value;
    },
    onAddCart() {
      if (this.isItemInCart(this.selected)) {
        this.$emit('gocart');
        return;
      }

      if (!this.selected) {
        this.buttonDisabled = true;
        return;
      }
      if (this.isAllPhotosMode) {
        this.$emit('withframe');
        return;
      }
      const payload = {
        selectedPhoto: this.selected.photoId,
        selectedPhotoUrl: this.selected.resources.preview,
        selectedFrameId: this.selectedFrameId,
        selectedFrameProductId: this.selectedProductId,
        autoShowPhotoId: this.$props.autoShowPhotoId,
      };
      this.$emit('addcart', payload);
    },
    onRemoveCart() {
      if (!this.selected) return;
      if (this.isAllPhotosMode) return;
      
      const item = this.isItemInCart(this.selected);
      if (item) this.$emit('removecart', item.shoppingCartItemId);
    },
  },
  mounted() {
    this.isAllPhotosMode = this.$props.allPhotosMode === true;

    if (this.$props.frames.length > 0) {
      if (this.$props.selectedFrameProductId.length > 0) {
        this.selectedProductId = this.$props.selectedFrameProductId;
      } else {
        this.selectedProductId = this.$props.frames[0].productId;
      }
      this.selectedFrameId = this.getSelectedFrameId();
    }

    this.setSelectedFrameValues();
    this.setCompetitorsValues();

    if (this.$props.selectedPhoto) {
      this.selected = { ...this.$props.selectedPhoto };
      this.onSelectedFrame();
    }
  },
}
</script>


<style lang="scss" scoped>
  @import "./photo-frame";
</style>