







import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "IconReset",
  props: {
    fill: {
      type: String,
      default: "var(--color-danger)"
    },
  },
})
export default class IconReset extends Vue {
}
